import abductors from "./abductors";
import abs from "./abs";
import adductors from "./adductors";
import biceps from "./biceps";
import calves from "./calves";
import cardiovascularSystem from "./cardiovascularSystem";
import delts from "./delts";
import forearms from "./forearms";
import glutes from "./glutes";
import hamstrings from "./hamstrings";
import lats from "./lats";
import levatorScapulae from "./levatorScapulae";
import pectorals from "./pectorals";
import quads from "./quads";
import serratusAnterior from "./serratusAnterior";
import spine from "./spine";
import traps from "./traps";
import triceps from "./triceps";
import upperBack from "./upperBack";

const list = [
    "abductors",
    "abs",
    "adductors",
    "biceps",
    "calves",
    "cardiovascular system",
    "delts",
    "forearms",
    "glutes",
    "hamstrings",
    "lats",
    "levator scapulae",
    "pectorals",
    "quads",
    "serratus anterior",
    "spine",
    "traps",
    "triceps",
    "upper back"
]

export default {
    abductors,
    abs,
    adductors,
    biceps,
    calves,
    cardiovascularSystem,
    delts,
    forearms,
    glutes,
    hamstrings,
    lats,
    levatorScapulae,
    pectorals,
    quads,
    serratusAnterior,
    spine,
    traps,
    triceps,
    upperBack,
};