export default [
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0597.gif",
        "id": "0597",
        "name": "lever seated hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3006.gif",
        "id": "3006",
        "name": "resistance band seated hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1774.gif",
        "id": "1774",
        "name": "side bridge hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0710.gif",
        "id": "0710",
        "name": "side hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1427.gif",
        "id": "1427",
        "name": "straight leg outer hip abductor",
        "target": "abductors"
    }
]