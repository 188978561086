export default [
    {
        "bodyPart": "upper arms",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0018.gif",
        "id": "0018",
        "name": "assisted standing triceps extension (with towel)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0019.gif",
        "id": "0019",
        "name": "assisted triceps dip (kneeling)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0975.gif",
        "id": "0975",
        "name": "band close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0998.gif",
        "id": "0998",
        "name": "band side triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0030.gif",
        "id": "0030",
        "name": "barbell close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0035.gif",
        "id": "0035",
        "name": "barbell decline close grip to skull press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1719.gif",
        "id": "1719",
        "name": "barbell incline close grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0048.gif",
        "id": "0048",
        "name": "barbell incline reverse-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0052.gif",
        "id": "0052",
        "name": "barbell jm bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1720.gif",
        "id": "1720",
        "name": "barbell lying back of the head tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0055.gif",
        "id": "0055",
        "name": "barbell lying close-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0056.gif",
        "id": "0056",
        "name": "barbell lying close-grip triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0057.gif",
        "id": "0057",
        "name": "barbell lying extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0061.gif",
        "id": "0061",
        "name": "barbell lying triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0060.gif",
        "id": "0060",
        "name": "barbell lying triceps extension skull crusher",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0065.gif",
        "id": "0065",
        "name": "barbell one arm floor press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1751.gif",
        "id": "1751",
        "name": "barbell pin presses",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2187.gif",
        "id": "2187",
        "name": "barbell reverse close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1721.gif",
        "id": "1721",
        "name": "barbell reverse grip skullcrusher",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1718.gif",
        "id": "1718",
        "name": "barbell seated close grip behind neck triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0092.gif",
        "id": "0092",
        "name": "barbell seated overhead triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0109.gif",
        "id": "0109",
        "name": "barbell standing overhead triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0129.gif",
        "id": "0129",
        "name": "bench dip (knees bent)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1399.gif",
        "id": "1399",
        "name": "bench dip on floor",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0137.gif",
        "id": "0137",
        "name": "body-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1771.gif",
        "id": "1771",
        "name": "bodyweight kneeling triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0149.gif",
        "id": "0149",
        "name": "cable alternate triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0152.gif",
        "id": "0152",
        "name": "cable concentration extension (on knee)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1722.gif",
        "id": "1722",
        "name": "cable high pulley overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0173.gif",
        "id": "0173",
        "name": "cable incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0860.gif",
        "id": "0860",
        "name": "cable kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0176.gif",
        "id": "0176",
        "name": "cable kneeling triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0186.gif",
        "id": "0186",
        "name": "cable lying triceps extension v. 2",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1723.gif",
        "id": "1723",
        "name": "cable one arm tricep pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0194.gif",
        "id": "0194",
        "name": "cable overhead triceps extension (rope attachment)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0201.gif",
        "id": "0201",
        "name": "cable pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0200.gif",
        "id": "0200",
        "name": "cable pushdown (with rope attachment)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0204.gif",
        "id": "0204",
        "name": "cable rear drive",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2406.gif",
        "id": "2406",
        "name": "cable reverse grip triceps pushdown (sz-bar) (with arm blaster)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0207.gif",
        "id": "0207",
        "name": "cable reverse-grip pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1724.gif",
        "id": "1724",
        "name": "cable rope high pulley overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1725.gif",
        "id": "1725",
        "name": "cable rope incline tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1726.gif",
        "id": "1726",
        "name": "cable rope lying on floor tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0231.gif",
        "id": "0231",
        "name": "cable standing one arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1727.gif",
        "id": "1727",
        "name": "cable standing reverse grip one arm overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0241.gif",
        "id": "0241",
        "name": "cable triceps pushdown (v-bar)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2405.gif",
        "id": "2405",
        "name": "cable triceps pushdown (v-bar) (with arm blaster)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1728.gif",
        "id": "1728",
        "name": "cable two arm tricep kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0259.gif",
        "id": "0259",
        "name": "close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2398.gif",
        "id": "2398",
        "name": "close-grip push-up (on knees)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0283.gif",
        "id": "0283",
        "name": "diamond push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1731.gif",
        "id": "1731",
        "name": "dumbbell close grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0296.gif",
        "id": "0296",
        "name": "dumbbell close-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1617.gif",
        "id": "1617",
        "name": "dumbbell decline one arm hammer press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0306.gif",
        "id": "0306",
        "name": "dumbbell decline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1732.gif",
        "id": "1732",
        "name": "dumbbell forward lunge triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1618.gif",
        "id": "1618",
        "name": "dumbbell incline hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1619.gif",
        "id": "1619",
        "name": "dumbbell incline one arm hammer press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1620.gif",
        "id": "1620",
        "name": "dumbbell incline one arm hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0330.gif",
        "id": "0330",
        "name": "dumbbell incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1733.gif",
        "id": "1733",
        "name": "dumbbell incline two arm extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0333.gif",
        "id": "0333",
        "name": "dumbbell kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1734.gif",
        "id": "1734",
        "name": "dumbbell kickbacks on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0337.gif",
        "id": "0337",
        "name": "dumbbell lying  extension (across face)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1729.gif",
        "id": "1729",
        "name": "dumbbell lying alternate extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0338.gif",
        "id": "0338",
        "name": "dumbbell lying elbow press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0344.gif",
        "id": "0344",
        "name": "dumbbell lying one arm pronated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0346.gif",
        "id": "0346",
        "name": "dumbbell lying one arm supinated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1735.gif",
        "id": "1735",
        "name": "dumbbell lying single extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0351.gif",
        "id": "0351",
        "name": "dumbbell lying triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0352.gif",
        "id": "0352",
        "name": "dumbbell neutral grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1736.gif",
        "id": "1736",
        "name": "dumbbell one arm french press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1621.gif",
        "id": "1621",
        "name": "dumbbell one arm hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0354.gif",
        "id": "0354",
        "name": "dumbbell one arm kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0362.gif",
        "id": "0362",
        "name": "dumbbell one arm triceps extension (on bench)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1623.gif",
        "id": "1623",
        "name": "dumbbell palms in incline bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0373.gif",
        "id": "0373",
        "name": "dumbbell pronate-grip triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0389.gif",
        "id": "0389",
        "name": "dumbbell seated bench extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1730.gif",
        "id": "1730",
        "name": "dumbbell seated bent over alternate kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1737.gif",
        "id": "1737",
        "name": "dumbbell seated bent over triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0394.gif",
        "id": "0394",
        "name": "dumbbell seated kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0398.gif",
        "id": "0398",
        "name": "dumbbell seated one arm kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1738.gif",
        "id": "1738",
        "name": "dumbbell seated reverse grip one arm overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2188.gif",
        "id": "2188",
        "name": "dumbbell seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1739.gif",
        "id": "1739",
        "name": "dumbbell standing alternating tricep kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1740.gif",
        "id": "1740",
        "name": "dumbbell standing bent over one arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1741.gif",
        "id": "1741",
        "name": "dumbbell standing bent over two arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0420.gif",
        "id": "0420",
        "name": "dumbbell standing kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0423.gif",
        "id": "0423",
        "name": "dumbbell standing one arm extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0430.gif",
        "id": "0430",
        "name": "dumbbell standing triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0436.gif",
        "id": "0436",
        "name": "dumbbell tate press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1742.gif",
        "id": "1742",
        "name": "dumbbell tricep kickback with stork stance",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1743.gif",
        "id": "1743",
        "name": "dumbbell twisting bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2189.gif",
        "id": "2189",
        "name": "dumbbells seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3287.gif",
        "id": "3287",
        "name": "elbow dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1744.gif",
        "id": "1744",
        "name": "exercise ball dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1745.gif",
        "id": "1745",
        "name": "exercise ball seated triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1746.gif",
        "id": "1746",
        "name": "exercise ball supine triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1747.gif",
        "id": "1747",
        "name": "ez bar french press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1748.gif",
        "id": "1748",
        "name": "ez bar lying close grip triceps extension behind head",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1749.gif",
        "id": "1749",
        "name": "ez bar standing french press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0448.gif",
        "id": "0448",
        "name": "ez barbell decline close grip face press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2186.gif",
        "id": "2186",
        "name": "ez barbell decline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0449.gif",
        "id": "0449",
        "name": "ez barbell incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0450.gif",
        "id": "0450",
        "name": "ez barbell jm bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0453.gif",
        "id": "0453",
        "name": "ez barbell seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2432.gif",
        "id": "2432",
        "name": "ez-bar close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3302.gif",
        "id": "3302",
        "name": "handstand",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0471.gif",
        "id": "0471",
        "name": "handstand push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3289.gif",
        "id": "3289",
        "name": "impossible dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0490.gif",
        "id": "0490",
        "name": "incline close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0591.gif",
        "id": "0591",
        "name": "lever overhand triceps dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1451.gif",
        "id": "1451",
        "name": "lever seated dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0607.gif",
        "id": "0607",
        "name": "lever triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1701.gif",
        "id": "1701",
        "name": "medicine ball close grip push up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1750.gif",
        "id": "1750",
        "name": "medicine ball supine chest throw",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2328.gif",
        "id": "2328",
        "name": "narrow push-up on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "olympic barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0637.gif",
        "id": "0637",
        "name": "olympic barbell triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0639.gif",
        "id": "0639",
        "name": "one arm dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0643.gif",
        "id": "0643",
        "name": "overhead triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0660.gif",
        "id": "0660",
        "name": "push-up close-grip off dumbbell",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1467.gif",
        "id": "1467",
        "name": "push-up on lower arms",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0672.gif",
        "id": "0672",
        "name": "reverse dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0677.gif",
        "id": "0677",
        "name": "ring dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0717.gif",
        "id": "0717",
        "name": "side push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "skierg machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2142.gif",
        "id": "2142",
        "name": "ski ergometer",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0751.gif",
        "id": "0751",
        "name": "smith close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1625.gif",
        "id": "1625",
        "name": "smith machine decline close grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1752.gif",
        "id": "1752",
        "name": "smith machine incline tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3291.gif",
        "id": "3291",
        "name": "stalder press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1753.gif",
        "id": "1753",
        "name": "three bench dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0814.gif",
        "id": "0814",
        "name": "triceps dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0812.gif",
        "id": "0812",
        "name": "triceps dip (bench leg)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0813.gif",
        "id": "0813",
        "name": "triceps dip (between benches)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0815.gif",
        "id": "0815",
        "name": "triceps dips floor",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0816.gif",
        "id": "0816",
        "name": "triceps press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0817.gif",
        "id": "0817",
        "name": "triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0830.gif",
        "id": "0830",
        "name": "weighted bench dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1754.gif",
        "id": "1754",
        "name": "weighted three bench dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1755.gif",
        "id": "1755",
        "name": "weighted tricep dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1767.gif",
        "id": "1767",
        "name": "weighted triceps dip on high parallel bars",
        "target": "triceps"
    }
]