export default [
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0746.gif",
        "id": "0746",
        "name": "smith back shrug",
        "target": "traps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0747.gif",
        "id": "0747",
        "name": "smith behind neck press",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0748.gif",
        "id": "0748",
        "name": "smith bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0749.gif",
        "id": "0749",
        "name": "smith bent knee good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1359.gif",
        "id": "1359",
        "name": "smith bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0750.gif",
        "id": "0750",
        "name": "smith chair squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0751.gif",
        "id": "0751",
        "name": "smith close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0752.gif",
        "id": "0752",
        "name": "smith deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0753.gif",
        "id": "0753",
        "name": "smith decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0754.gif",
        "id": "0754",
        "name": "smith decline reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1433.gif",
        "id": "1433",
        "name": "smith front squat (clean grip)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3281.gif",
        "id": "3281",
        "name": "smith full squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0755.gif",
        "id": "0755",
        "name": "smith hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0756.gif",
        "id": "0756",
        "name": "smith hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0757.gif",
        "id": "0757",
        "name": "smith incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0758.gif",
        "id": "0758",
        "name": "smith incline reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0759.gif",
        "id": "0759",
        "name": "smith incline shoulder raises",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0760.gif",
        "id": "0760",
        "name": "smith leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1434.gif",
        "id": "1434",
        "name": "smith low bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1683.gif",
        "id": "1683",
        "name": "smith machine bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1625.gif",
        "id": "1625",
        "name": "smith machine decline close grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1752.gif",
        "id": "1752",
        "name": "smith machine incline tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1626.gif",
        "id": "1626",
        "name": "smith machine reverse decline close grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0761.gif",
        "id": "0761",
        "name": "smith narrow row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1360.gif",
        "id": "1360",
        "name": "smith one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1393.gif",
        "id": "1393",
        "name": "smith one leg floor calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0762.gif",
        "id": "0762",
        "name": "smith rear delt row",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0763.gif",
        "id": "0763",
        "name": "smith reverse calf raises",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1394.gif",
        "id": "1394",
        "name": "smith reverse calf raises",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1361.gif",
        "id": "1361",
        "name": "smith reverse grip bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0764.gif",
        "id": "0764",
        "name": "smith reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1395.gif",
        "id": "1395",
        "name": "smith seated one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0765.gif",
        "id": "0765",
        "name": "smith seated shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1426.gif",
        "id": "1426",
        "name": "smith seated wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0766.gif",
        "id": "0766",
        "name": "smith shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0767.gif",
        "id": "0767",
        "name": "smith shrug",
        "target": "traps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0768.gif",
        "id": "0768",
        "name": "smith single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0769.gif",
        "id": "0769",
        "name": "smith sprint lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0770.gif",
        "id": "0770",
        "name": "smith squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0771.gif",
        "id": "0771",
        "name": "smith standing back wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0772.gif",
        "id": "0772",
        "name": "smith standing behind head military press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0773.gif",
        "id": "0773",
        "name": "smith standing leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0774.gif",
        "id": "0774",
        "name": "smith standing military press",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3142.gif",
        "id": "3142",
        "name": "smith sumo squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1396.gif",
        "id": "1396",
        "name": "smith toe raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0775.gif",
        "id": "0775",
        "name": "smith upright row",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1308.gif",
        "id": "1308",
        "name": "smith wide grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1309.gif",
        "id": "1309",
        "name": "smith wide grip decline bench press",
        "target": "pectorals"
    }
]