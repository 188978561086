import assisted from "./assisted";
import band from "./band";
import barbell from "./bodyWeight";
import bodyWeight from "./assisted";
import bosuBall from "./bosuBall";
import cable from "./cable";
import dumbbell from "./dumbbell";
import ellipticalMachine from "./ellipticalMachine";
import ezBarbell from "./ezBarbell";
import hammer from "./hammer";
import kettlebell from "./kettlebell";
import leverageMachine from "./leverageMachine";
import medicineBall from "./medicineBall";
import olympicBarbell from "./olympicBarbell";
import resistanceBand from "./resistanceBand";
import roller from "./roller";
import rope from "./assisted";
import skiergMachine from "./skiergMachine";
import sledMachine from "./sledMachine";
import smithMachine from "./smithMachine";
import stabilityBall from "./stabilityBall";
import stationaryBike from "./stationaryBike";
import stepmillMachine from "./stepmillMachine";
import tire from "./tire";
import trapBar from "./trapBar";
import upperBodyErgometer from "./upperBodyErgometer";
import weighted from "./weighted";
import wheelRoller from "./wheelRoller";

export const list = [
    "assisted",
    "band",
    "barbell",
    "body weight",
    "bosu ball",
    "cable",
    "dumbbell",
    "elliptical machine",
    "ez barbell",
    "hammer",
    "kettlebell",
    "leverage machine",
    "medicine ball",
    "olympic barbell",
    "resistance band",
    "roller",
    "rope",
    "skierg machine",
    "sled machine",
    "smith machine",
    "stability ball",
    "stationary bike",
    "stepmill machine",
    "tire",
    "trap bar",
    "upper body ergometer",
    "weighted",
    "wheel roller"
]

export default {
    assisted,
    band,
    barbell,
    bodyWeight,
    bosuBall,
    cable,
    dumbbell,
    ellipticalMachine,
    ezBarbell,
    hammer,
    kettlebell,
    leverageMachine,
    medicineBall,
    olympicBarbell,
    resistanceBand,
    roller,
    rope,
    skiergMachine,
    sledMachine,
    smithMachine,
    stabilityBall,
    stationaryBike,
    stepmillMachine,
    tire,
    trapBar,
    upperBodyErgometer,
    weighted,
    wheelRoller
}