export default [
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0001.gif",
        "id": "0001",
        "name": "3/4 sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0002.gif",
        "id": "0002",
        "name": "45° side bend",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0003.gif",
        "id": "0003",
        "name": "air bike",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0006.gif",
        "id": "0006",
        "name": "alternate heel touchers",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2355.gif",
        "id": "2355",
        "name": "arm slingers hanging bent knee legs",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2333.gif",
        "id": "2333",
        "name": "arm slingers hanging straight legs",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3204.gif",
        "id": "3204",
        "name": "arms overhead full sit-up (male)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0011.gif",
        "id": "0011",
        "name": "assisted hanging knee raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0010.gif",
        "id": "0010",
        "name": "assisted hanging knee raise with throw down",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0012.gif",
        "id": "0012",
        "name": "assisted lying leg raise with lateral throw down",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0013.gif",
        "id": "0013",
        "name": "assisted lying leg raise with throw down",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0014.gif",
        "id": "0014",
        "name": "assisted motion russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1714.gif",
        "id": "1714",
        "name": "assisted prone rectus femoris stretch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1758.gif",
        "id": "1758",
        "name": "assisted sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0969.gif",
        "id": "0969",
        "name": "band alternating v-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0971.gif",
        "id": "0971",
        "name": "band assisted wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0972.gif",
        "id": "0972",
        "name": "band bicycle crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0979.gif",
        "id": "0979",
        "name": "band horizontal pallof press",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0981.gif",
        "id": "0981",
        "name": "band jack knife sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0985.gif",
        "id": "0985",
        "name": "band kneeling twisting crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1002.gif",
        "id": "1002",
        "name": "band lying straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0992.gif",
        "id": "0992",
        "name": "band push sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1011.gif",
        "id": "1011",
        "name": "band seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1005.gif",
        "id": "1005",
        "name": "band standing crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1007.gif",
        "id": "1007",
        "name": "band standing twisting crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1014.gif",
        "id": "1014",
        "name": "band v-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1015.gif",
        "id": "1015",
        "name": "band vertical pallof press",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0071.gif",
        "id": "0071",
        "name": "barbell press sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0084.gif",
        "id": "0084",
        "name": "barbell rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0083.gif",
        "id": "0083",
        "name": "barbell rollerout from bench",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0094.gif",
        "id": "0094",
        "name": "barbell seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0096.gif",
        "id": "0096",
        "name": "barbell side bent v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2799.gif",
        "id": "2799",
        "name": "barbell sitted alternate leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2800.gif",
        "id": "2800",
        "name": "barbell sitted alternate leg raise (female)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0103.gif",
        "id": "0103",
        "name": "barbell standing ab rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0112.gif",
        "id": "0112",
        "name": "barbell standing twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3544.gif",
        "id": "3544",
        "name": "bodyweight incline side plank",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0138.gif",
        "id": "0138",
        "name": "bottoms-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2466.gif",
        "id": "2466",
        "name": "bridge - mountain climber (cross body)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0870.gif",
        "id": "0870",
        "name": "butt-ups",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0174.gif",
        "id": "0174",
        "name": "cable judo flip",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0175.gif",
        "id": "0175",
        "name": "cable kneeling crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0873.gif",
        "id": "0873",
        "name": "cable reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0211.gif",
        "id": "0211",
        "name": "cable russian twists (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0212.gif",
        "id": "0212",
        "name": "cable seated crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2399.gif",
        "id": "2399",
        "name": "cable seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0222.gif",
        "id": "0222",
        "name": "cable side bend",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0221.gif",
        "id": "0221",
        "name": "cable side bend crunch (bosu ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0223.gif",
        "id": "0223",
        "name": "cable side crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0226.gif",
        "id": "0226",
        "name": "cable standing crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0874.gif",
        "id": "0874",
        "name": "cable standing crunch (with rope attachment)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0230.gif",
        "id": "0230",
        "name": "cable standing lift",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0242.gif",
        "id": "0242",
        "name": "cable tuck reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0243.gif",
        "id": "0243",
        "name": "cable twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0862.gif",
        "id": "0862",
        "name": "cable twist (up-down)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2963.gif",
        "id": "2963",
        "name": "captains chair straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0260.gif",
        "id": "0260",
        "name": "cocoons",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1468.gif",
        "id": "1468",
        "name": "crab twist toe touch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0262.gif",
        "id": "0262",
        "name": "cross body crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0267.gif",
        "id": "0267",
        "name": "crunch (hands overhead)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0271.gif",
        "id": "0271",
        "name": "crunch (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0272.gif",
        "id": "0272",
        "name": "crunch (on stability ball, arms straight)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0274.gif",
        "id": "0274",
        "name": "crunch floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3016.gif",
        "id": "3016",
        "name": "curl-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0276.gif",
        "id": "0276",
        "name": "dead bug",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0277.gif",
        "id": "0277",
        "name": "decline crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0282.gif",
        "id": "0282",
        "name": "decline sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0407.gif",
        "id": "0407",
        "name": "dumbbell side bend",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0443.gif",
        "id": "0443",
        "name": "elbow-to-knee",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3303.gif",
        "id": "3303",
        "name": "flag",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0456.gif",
        "id": "0456",
        "name": "flexion leg sit up (bent knee)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0457.gif",
        "id": "0457",
        "name": "flexion leg sit up (straight arm)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2429.gif",
        "id": "2429",
        "name": "frog crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3301.gif",
        "id": "3301",
        "name": "frog planche",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3296.gif",
        "id": "3296",
        "name": "front lever",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0464.gif",
        "id": "0464",
        "name": "front plank with twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3315.gif",
        "id": "3315",
        "name": "full maltese",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3299.gif",
        "id": "3299",
        "name": "full planche",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0467.gif",
        "id": "0467",
        "name": "gorilla chin",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0469.gif",
        "id": "0469",
        "name": "groin crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3202.gif",
        "id": "3202",
        "name": "half sit-up (male)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1764.gif",
        "id": "1764",
        "name": "hanging leg hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0472.gif",
        "id": "0472",
        "name": "hanging leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1761.gif",
        "id": "1761",
        "name": "hanging oblique knee raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0473.gif",
        "id": "0473",
        "name": "hanging pike",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0474.gif",
        "id": "0474",
        "name": "hanging straight leg hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0475.gif",
        "id": "0475",
        "name": "hanging straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0476.gif",
        "id": "0476",
        "name": "hanging straight twisting leg hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0484.gif",
        "id": "0484",
        "name": "hip raise (bent knee)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1471.gif",
        "id": "1471",
        "name": "inchworm",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3698.gif",
        "id": "3698",
        "name": "inchworm v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0491.gif",
        "id": "0491",
        "name": "incline leg hip raise (leg straight)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0495.gif",
        "id": "0495",
        "name": "incline twisting sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0507.gif",
        "id": "0507",
        "name": "jackknife sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0508.gif",
        "id": "0508",
        "name": "janda sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0517.gif",
        "id": "0517",
        "name": "kettlebell advanced windmill",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0524.gif",
        "id": "0524",
        "name": "kettlebell bent press",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0530.gif",
        "id": "0530",
        "name": "kettlebell double windmill",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0532.gif",
        "id": "0532",
        "name": "kettlebell figure 8",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0554.gif",
        "id": "0554",
        "name": "kettlebell windmill",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3640.gif",
        "id": "3640",
        "name": "knee touch crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3239.gif",
        "id": "3239",
        "name": "kneeling plank tap shoulder (male)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3419.gif",
        "id": "3419",
        "name": "l-sit on floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0562.gif",
        "id": "0562",
        "name": "landmine 180",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3300.gif",
        "id": "3300",
        "name": "lean planche",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0570.gif",
        "id": "0570",
        "name": "leg pull in flat bench",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0583.gif",
        "id": "0583",
        "name": "lever kneeling twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1452.gif",
        "id": "1452",
        "name": "lever seated crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0595.gif",
        "id": "0595",
        "name": "lever seated crunch (chest pad)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3760.gif",
        "id": "3760",
        "name": "lever seated crunch v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0600.gif",
        "id": "0600",
        "name": "lever seated leg raise crunch ",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1688.gif",
        "id": "1688",
        "name": "lunge with twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2312.gif",
        "id": "2312",
        "name": "lying elbow to knee",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0620.gif",
        "id": "0620",
        "name": "lying leg raise flat bench",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0865.gif",
        "id": "0865",
        "name": "lying leg-hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0634.gif",
        "id": "0634",
        "name": "negative crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1495.gif",
        "id": "1495",
        "name": "oblique crunch v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0635.gif",
        "id": "0635",
        "name": "oblique crunches floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0640.gif",
        "id": "0640",
        "name": "one arm slam (with medicine ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0641.gif",
        "id": "0641",
        "name": "otis up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3147.gif",
        "id": "3147",
        "name": "pelvic tilt",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1687.gif",
        "id": "1687",
        "name": "posterior step to overhead reach",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3119.gif",
        "id": "3119",
        "name": "potty squat",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3665.gif",
        "id": "3665",
        "name": "power point plank",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3203.gif",
        "id": "3203",
        "name": "prisoner half sit-up (male)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1707.gif",
        "id": "1707",
        "name": "prone twist on stability ball",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0650.gif",
        "id": "0650",
        "name": "pull-in (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0664.gif",
        "id": "0664",
        "name": "push-up to side plank",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3201.gif",
        "id": "3201",
        "name": "quarter sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0872.gif",
        "id": "0872",
        "name": "reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3663.gif",
        "id": "3663",
        "name": "reverse plank with leg lift",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2204.gif",
        "id": "2204",
        "name": "roller body saw",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2206.gif",
        "id": "2206",
        "name": "roller reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0687.gif",
        "id": "0687",
        "name": "russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0689.gif",
        "id": "0689",
        "name": "seated leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0691.gif",
        "id": "0691",
        "name": "seated side crunch (wall)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3699.gif",
        "id": "3699",
        "name": "shoulder tap",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0705.gif",
        "id": "0705",
        "name": "side bridge v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0709.gif",
        "id": "0709",
        "name": "side hip (on parallel bars)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3213.gif",
        "id": "3213",
        "name": "side-to-side toe touch (male)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0735.gif",
        "id": "0735",
        "name": "sit-up v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3679.gif",
        "id": "3679",
        "name": "sit-up with arms on chest",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "hammer",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1496.gif",
        "id": "1496",
        "name": "sledge hammer",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0756.gif",
        "id": "0756",
        "name": "smith hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0777.gif",
        "id": "0777",
        "name": "spell caster",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2329.gif",
        "id": "2329",
        "name": "spine twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2297.gif",
        "id": "2297",
        "name": "stability ball crunch (full range hands behind head)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "wheel roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0796.gif",
        "id": "0796",
        "name": "standing wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3314.gif",
        "id": "3314",
        "name": "straddle maltese",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3298.gif",
        "id": "3298",
        "name": "straddle planche",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0805.gif",
        "id": "0805",
        "name": "suspended abdominal fallout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0807.gif",
        "id": "0807",
        "name": "suspended reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0871.gif",
        "id": "0871",
        "name": "tuck crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2802.gif",
        "id": "2802",
        "name": "twisted leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2801.gif",
        "id": "2801",
        "name": "twisted leg raise (female)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3420.gif",
        "id": "3420",
        "name": "v-sit on floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0826.gif",
        "id": "0826",
        "name": "vertical leg raise (on parallel bars)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0832.gif",
        "id": "0832",
        "name": "weighted crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3670.gif",
        "id": "3670",
        "name": "weighted decline sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2135.gif",
        "id": "2135",
        "name": "weighted front plank",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0866.gif",
        "id": "0866",
        "name": "weighted hanging leg-hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0840.gif",
        "id": "0840",
        "name": "weighted overhead crunch (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0846.gif",
        "id": "0846",
        "name": "weighted russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0845.gif",
        "id": "0845",
        "name": "weighted russian twist (legs up)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2371.gif",
        "id": "2371",
        "name": "weighted russian twist v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0849.gif",
        "id": "0849",
        "name": "weighted seated twist (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0850.gif",
        "id": "0850",
        "name": "weighted side bend (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "wheel roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0857.gif",
        "id": "0857",
        "name": "wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0858.gif",
        "id": "0858",
        "name": "wind sprints",
        "target": "abs"
    }
]