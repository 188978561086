export default [
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0009.gif",
        "id": "0009",
        "name": "assisted chest dip (kneeling)",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0015.gif",
        "id": "0015",
        "name": "assisted parallel close grip pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0017.gif",
        "id": "0017",
        "name": "assisted pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1431.gif",
        "id": "1431",
        "name": "assisted standing chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1432.gif",
        "id": "1432",
        "name": "assisted standing pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0019.gif",
        "id": "0019",
        "name": "assisted triceps dip (kneeling)",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2364.gif",
        "id": "2364",
        "name": "assisted wide-grip chest dip (kneeling)",
        "target": "pectorals"
    },
    {
        "bodyPart": "cardio",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2331.gif",
        "id": "2331",
        "name": "cycle cross trainer",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2287.gif",
        "id": "2287",
        "name": "lever alternate leg press ",
        "target": "quads"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0571.gif",
        "id": "0571",
        "name": "lever alternating narrow grip seated row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0572.gif",
        "id": "0572",
        "name": "lever assisted chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0573.gif",
        "id": "0573",
        "name": "lever back extension",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3200.gif",
        "id": "3200",
        "name": "lever bent-over row with v-bar ",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0575.gif",
        "id": "0575",
        "name": "lever bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2289.gif",
        "id": "2289",
        "name": "lever calf press ",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0577.gif",
        "id": "0577",
        "name": "lever chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0576.gif",
        "id": "0576",
        "name": "lever chest press ",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0578.gif",
        "id": "0578",
        "name": "lever deadlift ",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1300.gif",
        "id": "1300",
        "name": "lever decline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1253.gif",
        "id": "1253",
        "name": "lever donkey calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0579.gif",
        "id": "0579",
        "name": "lever front pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0580.gif",
        "id": "0580",
        "name": "lever gripless shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1439.gif",
        "id": "1439",
        "name": "lever gripless shrug v. 2",
        "target": "traps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2288.gif",
        "id": "2288",
        "name": "lever gripper hands ",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1615.gif",
        "id": "1615",
        "name": "lever hammer grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0581.gif",
        "id": "0581",
        "name": "lever high row ",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2286.gif",
        "id": "2286",
        "name": "lever hip extension v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2611.gif",
        "id": "2611",
        "name": "lever horizontal one leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1299.gif",
        "id": "1299",
        "name": "lever incline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1479.gif",
        "id": "1479",
        "name": "lever incline chest press v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0582.gif",
        "id": "0582",
        "name": "lever kneeling leg curl ",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0583.gif",
        "id": "0583",
        "name": "lever kneeling twist",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0584.gif",
        "id": "0584",
        "name": "lever lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0585.gif",
        "id": "0585",
        "name": "lever leg extension",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0586.gif",
        "id": "0586",
        "name": "lever lying leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3195.gif",
        "id": "3195",
        "name": "lever lying two-one leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0587.gif",
        "id": "0587",
        "name": "lever military press ",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0588.gif",
        "id": "0588",
        "name": "lever narrow grip seated row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1356.gif",
        "id": "1356",
        "name": "lever one arm lateral high row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1347.gif",
        "id": "1347",
        "name": "lever one arm lateral wide pulldown ",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0590.gif",
        "id": "0590",
        "name": "lever one arm shoulder press ",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0591.gif",
        "id": "0591",
        "name": "lever overhand triceps dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0592.gif",
        "id": "0592",
        "name": "lever preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1614.gif",
        "id": "1614",
        "name": "lever preacher curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2285.gif",
        "id": "2285",
        "name": "lever pullover ",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2736.gif",
        "id": "2736",
        "name": "lever reverse grip lateral pulldown ",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1616.gif",
        "id": "1616",
        "name": "lever reverse grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1348.gif",
        "id": "1348",
        "name": "lever reverse grip vertical row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0593.gif",
        "id": "0593",
        "name": "lever reverse hyperextension ",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1349.gif",
        "id": "1349",
        "name": "lever reverse t-bar row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2315.gif",
        "id": "2315",
        "name": "lever rotary calf",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2335.gif",
        "id": "2335",
        "name": "lever seated calf press",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0594.gif",
        "id": "0594",
        "name": "lever seated calf raise ",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1452.gif",
        "id": "1452",
        "name": "lever seated crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0595.gif",
        "id": "0595",
        "name": "lever seated crunch (chest pad)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3760.gif",
        "id": "3760",
        "name": "lever seated crunch v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1451.gif",
        "id": "1451",
        "name": "lever seated dip",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0596.gif",
        "id": "0596",
        "name": "lever seated fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3759.gif",
        "id": "3759",
        "name": "lever seated good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0597.gif",
        "id": "0597",
        "name": "lever seated hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0598.gif",
        "id": "0598",
        "name": "lever seated hip adduction",
        "target": "adductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0599.gif",
        "id": "0599",
        "name": "lever seated leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0600.gif",
        "id": "0600",
        "name": "lever seated leg raise crunch ",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0602.gif",
        "id": "0602",
        "name": "lever seated reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0601.gif",
        "id": "0601",
        "name": "lever seated reverse fly (parallel grip)",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1350.gif",
        "id": "1350",
        "name": "lever seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1385.gif",
        "id": "1385",
        "name": "lever seated squat calf raise on leg press machine",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0603.gif",
        "id": "0603",
        "name": "lever shoulder press ",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0869.gif",
        "id": "0869",
        "name": "lever shoulder press  v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2318.gif",
        "id": "2318",
        "name": "lever shoulder press  v. 3",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0604.gif",
        "id": "0604",
        "name": "lever shrug ",
        "target": "traps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0605.gif",
        "id": "0605",
        "name": "lever standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3758.gif",
        "id": "3758",
        "name": "lever standing chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0606.gif",
        "id": "0606",
        "name": "lever t bar row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1351.gif",
        "id": "1351",
        "name": "lever t-bar reverse grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0607.gif",
        "id": "0607",
        "name": "lever triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1313.gif",
        "id": "1313",
        "name": "lever unilateral row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1301.gif",
        "id": "1301",
        "name": "machine inner chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0673.gif",
        "id": "0673",
        "name": "reverse grip machine lat pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "cardio",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0798.gif",
        "id": "0798",
        "name": "stationary bike walk",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3666.gif",
        "id": "3666",
        "name": "walking on incline treadmill",
        "target": "cardiovascular system"
    }
]