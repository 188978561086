export default [
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0011.gif",
        "id": "0011",
        "name": "assisted hanging knee raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0010.gif",
        "id": "0010",
        "name": "assisted hanging knee raise with throw down",
        "target": "abs"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1708.gif",
        "id": "1708",
        "name": "assisted lying calves stretch",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1709.gif",
        "id": "1709",
        "name": "assisted lying glutes stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1710.gif",
        "id": "1710",
        "name": "assisted lying gluteus and piriformis stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0012.gif",
        "id": "0012",
        "name": "assisted lying leg raise with lateral throw down",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0013.gif",
        "id": "0013",
        "name": "assisted lying leg raise with throw down",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0016.gif",
        "id": "0016",
        "name": "assisted prone hamstring",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1713.gif",
        "id": "1713",
        "name": "assisted prone lying quads stretch",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1714.gif",
        "id": "1714",
        "name": "assisted prone rectus femoris stretch",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1716.gif",
        "id": "1716",
        "name": "assisted seated pectoralis major stretch with stability ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1712.gif",
        "id": "1712",
        "name": "assisted side lying adductor stretch",
        "target": "adductors"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1758.gif",
        "id": "1758",
        "name": "assisted sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0018.gif",
        "id": "0018",
        "name": "assisted standing triceps extension (with towel)",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1259.gif",
        "id": "1259",
        "name": "behind head chest stretch",
        "target": "pectorals"
    }
]