export default [
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1747.gif",
        "id": "1747",
        "name": "ez bar french press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3010.gif",
        "id": "3010",
        "name": "ez bar lying bent arms pullover",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1748.gif",
        "id": "1748",
        "name": "ez bar lying close grip triceps extension behind head",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1344.gif",
        "id": "1344",
        "name": "ez bar reverse grip bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1682.gif",
        "id": "1682",
        "name": "ez bar seated close grip concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1749.gif",
        "id": "1749",
        "name": "ez bar standing french press",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0445.gif",
        "id": "0445",
        "name": "ez barbell anti gravity press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1627.gif",
        "id": "1627",
        "name": "ez barbell close grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0446.gif",
        "id": "0446",
        "name": "ez barbell close-grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0447.gif",
        "id": "0447",
        "name": "ez barbell curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0448.gif",
        "id": "0448",
        "name": "ez barbell decline close grip face press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2186.gif",
        "id": "2186",
        "name": "ez barbell decline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0449.gif",
        "id": "0449",
        "name": "ez barbell incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0450.gif",
        "id": "0450",
        "name": "ez barbell jm bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0451.gif",
        "id": "0451",
        "name": "ez barbell reverse grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0452.gif",
        "id": "0452",
        "name": "ez barbell reverse grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1458.gif",
        "id": "1458",
        "name": "ez barbell seated curls",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0453.gif",
        "id": "0453",
        "name": "ez barbell seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0454.gif",
        "id": "0454",
        "name": "ez barbell spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1628.gif",
        "id": "1628",
        "name": "ez barbell spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2404.gif",
        "id": "2404",
        "name": "ez-bar biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2432.gif",
        "id": "2432",
        "name": "ez-bar close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2741.gif",
        "id": "2741",
        "name": "ez-barbell standing wide grip biceps curl",
        "target": "biceps"
    }
]