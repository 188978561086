export default [
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3236.gif",
        "id": "3236",
        "name": "resistance band hip thrusts on knees (female)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3007.gif",
        "id": "3007",
        "name": "resistance band leg extension",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3123.gif",
        "id": "3123",
        "name": "resistance band seated biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3124.gif",
        "id": "3124",
        "name": "resistance band seated chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3006.gif",
        "id": "3006",
        "name": "resistance band seated hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3122.gif",
        "id": "3122",
        "name": "resistance band seated shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3144.gif",
        "id": "3144",
        "name": "resistance band seated straight back row",
        "target": "upper back"
    }
]