export default [
    {
        "bodyPart": "chest",
        "equipment": "bosu ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1307.gif",
        "id": "1307",
        "name": "push up on bosu ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "bosu ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0653.gif",
        "id": "0653",
        "name": "push-up (bosu ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "bosu ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1705.gif",
        "id": "1705",
        "name": "squat on bosu ball",
        "target": "quads"
    }
]