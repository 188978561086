export default [
    {
        "bodyPart": "upper arms",
        "equipment": "olympic barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0636.gif",
        "id": "0636",
        "name": "olympic barbell hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "olympic barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0637.gif",
        "id": "0637",
        "name": "olympic barbell triceps extension",
        "target": "triceps"
    }
]