export default [
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1383.gif",
        "id": "1383",
        "name": "hack calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1384.gif",
        "id": "1384",
        "name": "hack one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1425.gif",
        "id": "1425",
        "name": "sled 45 degrees one leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0738.gif",
        "id": "0738",
        "name": "sled 45в° calf press",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0739.gif",
        "id": "0739",
        "name": "sled 45в° leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1464.gif",
        "id": "1464",
        "name": "sled 45в° leg press (back pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1463.gif",
        "id": "1463",
        "name": "sled 45в° leg press (side pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0740.gif",
        "id": "0740",
        "name": "sled 45в° leg wide press",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1391.gif",
        "id": "1391",
        "name": "sled calf press on leg press",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0741.gif",
        "id": "0741",
        "name": "sled closer hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0742.gif",
        "id": "0742",
        "name": "sled forward angled calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0743.gif",
        "id": "0743",
        "name": "sled hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2334.gif",
        "id": "2334",
        "name": "sled lying calf press",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0744.gif",
        "id": "0744",
        "name": "sled lying squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1392.gif",
        "id": "1392",
        "name": "sled one leg calf press on leg press",
        "target": "calves"
    }
]