export default [
    {
        "bodyPart": "neck",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1403.gif",
        "id": "1403",
        "name": "neck side stretch",
        "target": "levator scapulae"
    },
    {
        "bodyPart": "neck",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0716.gif",
        "id": "0716",
        "name": "side push neck stretch",
        "target": "levator scapulae"
    }
]