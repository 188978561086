export default [
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3214.gif",
        "id": "3214",
        "name": "arms apart circular toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1709.gif",
        "id": "1709",
        "name": "assisted lying glutes stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1710.gif",
        "id": "1710",
        "name": "assisted lying gluteus and piriformis stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0980.gif",
        "id": "0980",
        "name": "band bent-over hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1408.gif",
        "id": "1408",
        "name": "band hip lift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0984.gif",
        "id": "0984",
        "name": "band lying hip internal rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0991.gif",
        "id": "0991",
        "name": "band pull through",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0996.gif",
        "id": "0996",
        "name": "band seated hip internal rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1004.gif",
        "id": "1004",
        "name": "band squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1003.gif",
        "id": "1003",
        "name": "band squat row",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1008.gif",
        "id": "1008",
        "name": "band step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1009.gif",
        "id": "1009",
        "name": "band stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1023.gif",
        "id": "1023",
        "name": "band straight back stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0029.gif",
        "id": "0029",
        "name": "barbell clean-grip front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0032.gif",
        "id": "0032",
        "name": "barbell deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0039.gif",
        "id": "0039",
        "name": "barbell front chest squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0042.gif",
        "id": "0042",
        "name": "barbell front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0043.gif",
        "id": "0043",
        "name": "barbell full squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1461.gif",
        "id": "1461",
        "name": "barbell full squat (back pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1462.gif",
        "id": "1462",
        "name": "barbell full squat (side pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1545.gif",
        "id": "1545",
        "name": "barbell full zercher squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1409.gif",
        "id": "1409",
        "name": "barbell glute bridge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3562.gif",
        "id": "3562",
        "name": "barbell glute bridge two legs on bench (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0046.gif",
        "id": "0046",
        "name": "barbell hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1436.gif",
        "id": "1436",
        "name": "barbell high bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0051.gif",
        "id": "0051",
        "name": "barbell jefferson squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0053.gif",
        "id": "0053",
        "name": "barbell jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1410.gif",
        "id": "1410",
        "name": "barbell lateral lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1435.gif",
        "id": "1435",
        "name": "barbell low bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0054.gif",
        "id": "0054",
        "name": "barbell lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0058.gif",
        "id": "0058",
        "name": "barbell lying lifting (on hip)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0063.gif",
        "id": "0063",
        "name": "barbell narrow stance squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0066.gif",
        "id": "0066",
        "name": "barbell one arm side deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0074.gif",
        "id": "0074",
        "name": "barbell rack pull",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0078.gif",
        "id": "0078",
        "name": "barbell rear lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0077.gif",
        "id": "0077",
        "name": "barbell rear lunge v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0085.gif",
        "id": "0085",
        "name": "barbell romanian deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0090.gif",
        "id": "0090",
        "name": "barbell seated good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1756.gif",
        "id": "1756",
        "name": "barbell single leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0101.gif",
        "id": "0101",
        "name": "barbell speed squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0114.gif",
        "id": "0114",
        "name": "barbell step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0115.gif",
        "id": "0115",
        "name": "barbell stiff leg good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0117.gif",
        "id": "0117",
        "name": "barbell sumo deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0127.gif",
        "id": "0127",
        "name": "barbell zercher squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3212.gif",
        "id": "3212",
        "name": "basic toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0130.gif",
        "id": "0130",
        "name": "bench hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3639.gif",
        "id": "3639",
        "name": "bent knee lying twist (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3543.gif",
        "id": "3543",
        "name": "bodyweight drop jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0157.gif",
        "id": "0157",
        "name": "cable deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0196.gif",
        "id": "0196",
        "name": "cable pull through (with rope)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0228.gif",
        "id": "0228",
        "name": "cable standing hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3769.gif",
        "id": "3769",
        "name": "curtsey squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0291.gif",
        "id": "0291",
        "name": "dumbbell bench squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0295.gif",
        "id": "0295",
        "name": "dumbbell clean",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3635.gif",
        "id": "3635",
        "name": "dumbbell contralateral forward lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0300.gif",
        "id": "0300",
        "name": "dumbbell deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0336.gif",
        "id": "0336",
        "name": "dumbbell lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3888.gif",
        "id": "3888",
        "name": "dumbbell one arm snatch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0371.gif",
        "id": "0371",
        "name": "dumbbell plyo squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0381.gif",
        "id": "0381",
        "name": "dumbbell rear lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1459.gif",
        "id": "1459",
        "name": "dumbbell romanian deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1757.gif",
        "id": "1757",
        "name": "dumbbell single leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2805.gif",
        "id": "2805",
        "name": "dumbbell single leg deadlift with stepbox support",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0411.gif",
        "id": "0411",
        "name": "dumbbell single leg squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0413.gif",
        "id": "0413",
        "name": "dumbbell squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0431.gif",
        "id": "0431",
        "name": "dumbbell step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0432.gif",
        "id": "0432",
        "name": "dumbbell stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0434.gif",
        "id": "0434",
        "name": "dumbbell straight leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2808.gif",
        "id": "2808",
        "name": "dumbbell sumo pull through",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1559.gif",
        "id": "1559",
        "name": "exercise ball hip flexor stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1416.gif",
        "id": "1416",
        "name": "exercise ball one leg prone lower body rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1417.gif",
        "id": "1417",
        "name": "exercise ball one legged diagonal kick hamstring curl",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0459.gif",
        "id": "0459",
        "name": "flutter kicks",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3470.gif",
        "id": "3470",
        "name": "forward lunge (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3194.gif",
        "id": "3194",
        "name": "frankenstein squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3561.gif",
        "id": "3561",
        "name": "glute bridge march",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3523.gif",
        "id": "3523",
        "name": "glute bridge two legs on bench (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3218.gif",
        "id": "3218",
        "name": "hands clasped circular toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3215.gif",
        "id": "3215",
        "name": "hands reversed clasped circular toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1418.gif",
        "id": "1418",
        "name": "hug keens to chest",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1419.gif",
        "id": "1419",
        "name": "iron cross stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0514.gif",
        "id": "0514",
        "name": "jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0513.gif",
        "id": "0513",
        "name": "jump squat v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0533.gif",
        "id": "0533",
        "name": "kettlebell front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0534.gif",
        "id": "0534",
        "name": "kettlebell goblet squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0536.gif",
        "id": "0536",
        "name": "kettlebell lunge pass through",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0544.gif",
        "id": "0544",
        "name": "kettlebell pistol squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0549.gif",
        "id": "0549",
        "name": "kettlebell swing",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0551.gif",
        "id": "0551",
        "name": "kettlebell turkish get up (squat style)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1420.gif",
        "id": "1420",
        "name": "kneeling jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0578.gif",
        "id": "0578",
        "name": "lever deadlift ",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2286.gif",
        "id": "2286",
        "name": "lever hip extension v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2611.gif",
        "id": "2611",
        "name": "lever horizontal one leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0593.gif",
        "id": "0593",
        "name": "lever reverse hyperextension ",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3759.gif",
        "id": "3759",
        "name": "lever seated good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3013.gif",
        "id": "3013",
        "name": "low glute bridge on floor",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3582.gif",
        "id": "3582",
        "name": "lunge with jump",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0624.gif",
        "id": "0624",
        "name": "march sit (wall)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0628.gif",
        "id": "0628",
        "name": "monster walk",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1476.gif",
        "id": "1476",
        "name": "one leg squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0642.gif",
        "id": "0642",
        "name": "outside leg kick push-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1422.gif",
        "id": "1422",
        "name": "pelvic tilt into bridge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3662.gif",
        "id": "3662",
        "name": "pike-to-cobra push-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3132.gif",
        "id": "3132",
        "name": "potty squat with support",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0661.gif",
        "id": "0661",
        "name": "push-up inside leg kick",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0668.gif",
        "id": "0668",
        "name": "rear decline bridge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3236.gif",
        "id": "3236",
        "name": "resistance band hip thrusts on knees (female)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0675.gif",
        "id": "0675",
        "name": "reverse hyper extension (on stability ball)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1423.gif",
        "id": "1423",
        "name": "reverse hyper on flat bench",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2571.gif",
        "id": "2571",
        "name": "rocking frog stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2205.gif",
        "id": "2205",
        "name": "roller hip lat stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2202.gif",
        "id": "2202",
        "name": "roller hip stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1424.gif",
        "id": "1424",
        "name": "seated glute stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2567.gif",
        "id": "2567",
        "name": "seated piriformis stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3645.gif",
        "id": "3645",
        "name": "single leg bridge with outstretched leg",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1759.gif",
        "id": "1759",
        "name": "single leg squat (pistol) male",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1425.gif",
        "id": "1425",
        "name": "sled 45 degrees one leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0739.gif",
        "id": "0739",
        "name": "sled 45в° leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1464.gif",
        "id": "1464",
        "name": "sled 45в° leg press (back pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1463.gif",
        "id": "1463",
        "name": "sled 45в° leg press (side pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0740.gif",
        "id": "0740",
        "name": "sled 45в° leg wide press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0741.gif",
        "id": "0741",
        "name": "sled closer hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0743.gif",
        "id": "0743",
        "name": "sled hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0744.gif",
        "id": "0744",
        "name": "sled lying squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0749.gif",
        "id": "0749",
        "name": "smith bent knee good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0752.gif",
        "id": "0752",
        "name": "smith deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1433.gif",
        "id": "1433",
        "name": "smith front squat (clean grip)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3281.gif",
        "id": "3281",
        "name": "smith full squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0755.gif",
        "id": "0755",
        "name": "smith hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0760.gif",
        "id": "0760",
        "name": "smith leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1434.gif",
        "id": "1434",
        "name": "smith low bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0769.gif",
        "id": "0769",
        "name": "smith sprint lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0770.gif",
        "id": "0770",
        "name": "smith squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3142.gif",
        "id": "3142",
        "name": "smith sumo squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0778.gif",
        "id": "0778",
        "name": "spider crawl push up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3433.gif",
        "id": "3433",
        "name": "swimmer kicks v. 2 (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "tire",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2459.gif",
        "id": "2459",
        "name": "tire flip",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "trap bar",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0811.gif",
        "id": "0811",
        "name": "trap bar deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1466.gif",
        "id": "1466",
        "name": "twist hip lift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1460.gif",
        "id": "1460",
        "name": "walking lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3643.gif",
        "id": "3643",
        "name": "weighted cossack squats (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3644.gif",
        "id": "3644",
        "name": "weighted lunge with swing",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0852.gif",
        "id": "0852",
        "name": "weighted squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3642.gif",
        "id": "3642",
        "name": "weighted stretch lunge",
        "target": "glutes"
    }
]