export default [
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3294.gif",
        "id": "3294",
        "name": "archer push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0009.gif",
        "id": "0009",
        "name": "assisted chest dip (kneeling)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1716.gif",
        "id": "1716",
        "name": "assisted seated pectoralis major stretch with stability ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2364.gif",
        "id": "2364",
        "name": "assisted wide-grip chest dip (kneeling)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1254.gif",
        "id": "1254",
        "name": "band bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0989.gif",
        "id": "0989",
        "name": "band one arm twisting chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0025.gif",
        "id": "0025",
        "name": "barbell bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0033.gif",
        "id": "0033",
        "name": "barbell decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1255.gif",
        "id": "1255",
        "name": "barbell decline pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0036.gif",
        "id": "0036",
        "name": "barbell decline wide-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0040.gif",
        "id": "0040",
        "name": "barbell front raise and pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0045.gif",
        "id": "0045",
        "name": "barbell guillotine bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0047.gif",
        "id": "0047",
        "name": "barbell incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1256.gif",
        "id": "1256",
        "name": "barbell reverse grip decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1257.gif",
        "id": "1257",
        "name": "barbell reverse grip incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0122.gif",
        "id": "0122",
        "name": "barbell wide bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1258.gif",
        "id": "1258",
        "name": "barbell wide reverse grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1259.gif",
        "id": "1259",
        "name": "behind head chest stretch",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0151.gif",
        "id": "0151",
        "name": "cable bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0155.gif",
        "id": "0155",
        "name": "cable cross-over variation",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0158.gif",
        "id": "0158",
        "name": "cable decline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1260.gif",
        "id": "1260",
        "name": "cable decline one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1261.gif",
        "id": "1261",
        "name": "cable decline press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0169.gif",
        "id": "0169",
        "name": "cable incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0171.gif",
        "id": "0171",
        "name": "cable incline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0170.gif",
        "id": "0170",
        "name": "cable incline fly (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0179.gif",
        "id": "0179",
        "name": "cable low fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0185.gif",
        "id": "0185",
        "name": "cable lying fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0188.gif",
        "id": "0188",
        "name": "cable middle fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1262.gif",
        "id": "1262",
        "name": "cable one arm decline chest fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1263.gif",
        "id": "1263",
        "name": "cable one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1264.gif",
        "id": "1264",
        "name": "cable one arm incline fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1265.gif",
        "id": "1265",
        "name": "cable one arm incline press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1266.gif",
        "id": "1266",
        "name": "cable one arm incline press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0191.gif",
        "id": "0191",
        "name": "cable one arm lateral bent-over",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1267.gif",
        "id": "1267",
        "name": "cable one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1268.gif",
        "id": "1268",
        "name": "cable press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2144.gif",
        "id": "2144",
        "name": "cable seated chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0227.gif",
        "id": "0227",
        "name": "cable standing fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1269.gif",
        "id": "1269",
        "name": "cable standing up straight crossovers",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1270.gif",
        "id": "1270",
        "name": "cable upper chest crossovers",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1271.gif",
        "id": "1271",
        "name": "chest and front of shoulder stretch",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0251.gif",
        "id": "0251",
        "name": "chest dip",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1430.gif",
        "id": "1430",
        "name": "chest dip (on dip-pull-up cage)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2462.gif",
        "id": "2462",
        "name": "chest dip on straight bar",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1272.gif",
        "id": "1272",
        "name": "chest stretch with exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3216.gif",
        "id": "3216",
        "name": "chest tap push-up (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1273.gif",
        "id": "1273",
        "name": "clap push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0258.gif",
        "id": "0258",
        "name": "clock push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0279.gif",
        "id": "0279",
        "name": "decline push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1274.gif",
        "id": "1274",
        "name": "deep push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1275.gif",
        "id": "1275",
        "name": "drop push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0288.gif",
        "id": "0288",
        "name": "dumbbell around pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0289.gif",
        "id": "0289",
        "name": "dumbbell bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0301.gif",
        "id": "0301",
        "name": "dumbbell decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0302.gif",
        "id": "0302",
        "name": "dumbbell decline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0303.gif",
        "id": "0303",
        "name": "dumbbell decline hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1276.gif",
        "id": "1276",
        "name": "dumbbell decline one arm fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0307.gif",
        "id": "0307",
        "name": "dumbbell decline twist fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0308.gif",
        "id": "0308",
        "name": "dumbbell fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1277.gif",
        "id": "1277",
        "name": "dumbbell fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3545.gif",
        "id": "3545",
        "name": "dumbbell incline alternate press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0314.gif",
        "id": "0314",
        "name": "dumbbell incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0316.gif",
        "id": "0316",
        "name": "dumbbell incline breeding",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0319.gif",
        "id": "0319",
        "name": "dumbbell incline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1278.gif",
        "id": "1278",
        "name": "dumbbell incline fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0321.gif",
        "id": "0321",
        "name": "dumbbell incline hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1279.gif",
        "id": "1279",
        "name": "dumbbell incline one arm fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1280.gif",
        "id": "1280",
        "name": "dumbbell incline one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1281.gif",
        "id": "1281",
        "name": "dumbbell incline one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1282.gif",
        "id": "1282",
        "name": "dumbbell incline one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0324.gif",
        "id": "0324",
        "name": "dumbbell incline palm-in press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1283.gif",
        "id": "1283",
        "name": "dumbbell incline press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0331.gif",
        "id": "0331",
        "name": "dumbbell incline twisted flyes",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0340.gif",
        "id": "0340",
        "name": "dumbbell lying hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0343.gif",
        "id": "0343",
        "name": "dumbbell lying one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0342.gif",
        "id": "0342",
        "name": "dumbbell lying one arm press v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1284.gif",
        "id": "1284",
        "name": "dumbbell lying pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1285.gif",
        "id": "1285",
        "name": "dumbbell one arm bench fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1286.gif",
        "id": "1286",
        "name": "dumbbell one arm chest fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1287.gif",
        "id": "1287",
        "name": "dumbbell one arm decline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1288.gif",
        "id": "1288",
        "name": "dumbbell one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1289.gif",
        "id": "1289",
        "name": "dumbbell one arm incline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1290.gif",
        "id": "1290",
        "name": "dumbbell one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1291.gif",
        "id": "1291",
        "name": "dumbbell one arm pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1622.gif",
        "id": "1622",
        "name": "dumbbell one arm reverse grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1292.gif",
        "id": "1292",
        "name": "dumbbell one leg fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1293.gif",
        "id": "1293",
        "name": "dumbbell press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0375.gif",
        "id": "0375",
        "name": "dumbbell pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1294.gif",
        "id": "1294",
        "name": "dumbbell pullover hip extension on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1295.gif",
        "id": "1295",
        "name": "dumbbell pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1624.gif",
        "id": "1624",
        "name": "dumbbell reverse bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0433.gif",
        "id": "0433",
        "name": "dumbbell straight arm pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1167.gif",
        "id": "1167",
        "name": "dynamic chest stretch (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1296.gif",
        "id": "1296",
        "name": "exercise ball pike push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0458.gif",
        "id": "0458",
        "name": "floor fly (with barbell)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3327.gif",
        "id": "3327",
        "name": "full planche push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "upper body ergometer",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2139.gif",
        "id": "2139",
        "name": "hands bike",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3234.gif",
        "id": "3234",
        "name": "hyght dumbbell fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0492.gif",
        "id": "0492",
        "name": "incline push up depth jump",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0493.gif",
        "id": "0493",
        "name": "incline push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3785.gif",
        "id": "3785",
        "name": "incline push-up (on box)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0494.gif",
        "id": "0494",
        "name": "incline reverse grip push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1297.gif",
        "id": "1297",
        "name": "isometric chest squeeze",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0500.gif",
        "id": "0500",
        "name": "isometric wipers",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0519.gif",
        "id": "0519",
        "name": "kettlebell alternating press on floor",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0531.gif",
        "id": "0531",
        "name": "kettlebell extended range one arm press on floor",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1298.gif",
        "id": "1298",
        "name": "kettlebell one arm floor press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0545.gif",
        "id": "0545",
        "name": "kettlebell plyo push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3211.gif",
        "id": "3211",
        "name": "kneeling push-up (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3288.gif",
        "id": "3288",
        "name": "korean dips",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0577.gif",
        "id": "0577",
        "name": "lever chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0576.gif",
        "id": "0576",
        "name": "lever chest press ",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1300.gif",
        "id": "1300",
        "name": "lever decline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1299.gif",
        "id": "1299",
        "name": "lever incline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1479.gif",
        "id": "1479",
        "name": "lever incline chest press v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0596.gif",
        "id": "0596",
        "name": "lever seated fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3758.gif",
        "id": "3758",
        "name": "lever standing chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1301.gif",
        "id": "1301",
        "name": "machine inner chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1302.gif",
        "id": "1302",
        "name": "medicine ball chest pass",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1303.gif",
        "id": "1303",
        "name": "medicine ball chest push from 3 point stance",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1304.gif",
        "id": "1304",
        "name": "medicine ball chest push multiple response",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1305.gif",
        "id": "1305",
        "name": "medicine ball chest push single response",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1312.gif",
        "id": "1312",
        "name": "medicine ball chest push with run release",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3217.gif",
        "id": "3217",
        "name": "modified hindu push-up (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1306.gif",
        "id": "1306",
        "name": "plyo push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1689.gif",
        "id": "1689",
        "name": "push and pull bodyweight",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "bosu ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1307.gif",
        "id": "1307",
        "name": "push up on bosu ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0662.gif",
        "id": "0662",
        "name": "push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "bosu ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0653.gif",
        "id": "0653",
        "name": "push-up (bosu ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0655.gif",
        "id": "0655",
        "name": "push-up (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0656.gif",
        "id": "0656",
        "name": "push-up (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0659.gif",
        "id": "0659",
        "name": "push-up (wall)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0658.gif",
        "id": "0658",
        "name": "push-up (wall) v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0663.gif",
        "id": "0663",
        "name": "push-up medicine ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3145.gif",
        "id": "3145",
        "name": "push-up plus",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0666.gif",
        "id": "0666",
        "name": "raise single arm push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3124.gif",
        "id": "3124",
        "name": "resistance band seated chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2203.gif",
        "id": "2203",
        "name": "roller seated shoulder flexor depresor retractor",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2209.gif",
        "id": "2209",
        "name": "roller seated single leg shoulder flexor depresor retractor",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0699.gif",
        "id": "0699",
        "name": "shoulder tap push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0725.gif",
        "id": "0725",
        "name": "single arm push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0748.gif",
        "id": "0748",
        "name": "smith bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0753.gif",
        "id": "0753",
        "name": "smith decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0754.gif",
        "id": "0754",
        "name": "smith decline reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0757.gif",
        "id": "0757",
        "name": "smith incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0758.gif",
        "id": "0758",
        "name": "smith incline reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1626.gif",
        "id": "1626",
        "name": "smith machine reverse decline close grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0764.gif",
        "id": "0764",
        "name": "smith reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1308.gif",
        "id": "1308",
        "name": "smith wide grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1309.gif",
        "id": "1309",
        "name": "smith wide grip decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0803.gif",
        "id": "0803",
        "name": "superman push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0806.gif",
        "id": "0806",
        "name": "suspended push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1310.gif",
        "id": "1310",
        "name": "weighted drop push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3313.gif",
        "id": "3313",
        "name": "weighted straight bar dip",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0856.gif",
        "id": "0856",
        "name": "weighted svend press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1311.gif",
        "id": "1311",
        "name": "wide hand push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2363.gif",
        "id": "2363",
        "name": "wide-grip chest dip on high parallel bars",
        "target": "pectorals"
    }
]