export default [
    {
        "bodyPart": "back",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2208.gif",
        "id": "2208",
        "name": "roller back stretch",
        "target": "spine"
    },
    {
        "bodyPart": "waist",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2204.gif",
        "id": "2204",
        "name": "roller body saw",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2205.gif",
        "id": "2205",
        "name": "roller hip lat stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2202.gif",
        "id": "2202",
        "name": "roller hip stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2206.gif",
        "id": "2206",
        "name": "roller reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2203.gif",
        "id": "2203",
        "name": "roller seated shoulder flexor depresor retractor",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2209.gif",
        "id": "2209",
        "name": "roller seated single leg shoulder flexor depresor retractor",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2207.gif",
        "id": "2207",
        "name": "roller side lat stretch",
        "target": "lats"
    }
]