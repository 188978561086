export default [
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0517.gif",
        "id": "0517",
        "name": "kettlebell advanced windmill",
        "target": "abs"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0518.gif",
        "id": "0518",
        "name": "kettlebell alternating hang clean",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0520.gif",
        "id": "0520",
        "name": "kettlebell alternating press",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0519.gif",
        "id": "0519",
        "name": "kettlebell alternating press on floor",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0521.gif",
        "id": "0521",
        "name": "kettlebell alternating renegade row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0522.gif",
        "id": "0522",
        "name": "kettlebell alternating row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0523.gif",
        "id": "0523",
        "name": "kettlebell arnold press",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0524.gif",
        "id": "0524",
        "name": "kettlebell bent press",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0525.gif",
        "id": "0525",
        "name": "kettlebell bottoms up clean from the hang position",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0526.gif",
        "id": "0526",
        "name": "kettlebell double alternating hang clean",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0527.gif",
        "id": "0527",
        "name": "kettlebell double jerk",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0528.gif",
        "id": "0528",
        "name": "kettlebell double push press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0529.gif",
        "id": "0529",
        "name": "kettlebell double snatch",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0530.gif",
        "id": "0530",
        "name": "kettlebell double windmill",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0531.gif",
        "id": "0531",
        "name": "kettlebell extended range one arm press on floor",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0532.gif",
        "id": "0532",
        "name": "kettlebell figure 8",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0533.gif",
        "id": "0533",
        "name": "kettlebell front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0534.gif",
        "id": "0534",
        "name": "kettlebell goblet squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0535.gif",
        "id": "0535",
        "name": "kettlebell hang clean",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0536.gif",
        "id": "0536",
        "name": "kettlebell lunge pass through",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0537.gif",
        "id": "0537",
        "name": "kettlebell one arm clean and jerk",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1298.gif",
        "id": "1298",
        "name": "kettlebell one arm floor press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0538.gif",
        "id": "0538",
        "name": "kettlebell one arm jerk",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0539.gif",
        "id": "0539",
        "name": "kettlebell one arm military press to the side",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0540.gif",
        "id": "0540",
        "name": "kettlebell one arm push press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0541.gif",
        "id": "0541",
        "name": "kettlebell one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0542.gif",
        "id": "0542",
        "name": "kettlebell one arm snatch",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0543.gif",
        "id": "0543",
        "name": "kettlebell pirate supper legs",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0544.gif",
        "id": "0544",
        "name": "kettlebell pistol squat",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0545.gif",
        "id": "0545",
        "name": "kettlebell plyo push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0546.gif",
        "id": "0546",
        "name": "kettlebell seated press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1438.gif",
        "id": "1438",
        "name": "kettlebell seated two arm military press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0547.gif",
        "id": "0547",
        "name": "kettlebell seesaw press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0548.gif",
        "id": "0548",
        "name": "kettlebell sumo high pull",
        "target": "traps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0549.gif",
        "id": "0549",
        "name": "kettlebell swing",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0550.gif",
        "id": "0550",
        "name": "kettlebell thruster",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0551.gif",
        "id": "0551",
        "name": "kettlebell turkish get up (squat style)",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0552.gif",
        "id": "0552",
        "name": "kettlebell two arm clean",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0553.gif",
        "id": "0553",
        "name": "kettlebell two arm military press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1345.gif",
        "id": "1345",
        "name": "kettlebell two arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0554.gif",
        "id": "0554",
        "name": "kettlebell windmill",
        "target": "abs"
    }
]