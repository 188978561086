export default [
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1314.gif",
        "id": "1314",
        "name": "back extension on exercise ball",
        "target": "spine"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1272.gif",
        "id": "1272",
        "name": "chest stretch with exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0271.gif",
        "id": "0271",
        "name": "crunch (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0272.gif",
        "id": "0272",
        "name": "crunch (on stability ball, arms straight)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1332.gif",
        "id": "1332",
        "name": "exercise ball alternating arm ups",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1333.gif",
        "id": "1333",
        "name": "exercise ball back extension with arms extended",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1334.gif",
        "id": "1334",
        "name": "exercise ball back extension with hands behind head",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1335.gif",
        "id": "1335",
        "name": "exercise ball back extension with knees off ground",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1336.gif",
        "id": "1336",
        "name": "exercise ball back extension with rotation",
        "target": "spine"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1744.gif",
        "id": "1744",
        "name": "exercise ball dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1559.gif",
        "id": "1559",
        "name": "exercise ball hip flexor stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1338.gif",
        "id": "1338",
        "name": "exercise ball hug",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1339.gif",
        "id": "1339",
        "name": "exercise ball lat stretch",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1341.gif",
        "id": "1341",
        "name": "exercise ball lower back stretch (pyramid)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1342.gif",
        "id": "1342",
        "name": "exercise ball lying side lat stretch",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1416.gif",
        "id": "1416",
        "name": "exercise ball one leg prone lower body rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1417.gif",
        "id": "1417",
        "name": "exercise ball one legged diagonal kick hamstring curl",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1296.gif",
        "id": "1296",
        "name": "exercise ball pike push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1343.gif",
        "id": "1343",
        "name": "exercise ball prone leg raise",
        "target": "spine"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1560.gif",
        "id": "1560",
        "name": "exercise ball seated hamstring stretch",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1745.gif",
        "id": "1745",
        "name": "exercise ball seated triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2328.gif",
        "id": "2328",
        "name": "narrow push-up on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1707.gif",
        "id": "1707",
        "name": "prone twist on stability ball",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0650.gif",
        "id": "0650",
        "name": "pull-in (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0655.gif",
        "id": "0655",
        "name": "push-up (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0656.gif",
        "id": "0656",
        "name": "push-up (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0675.gif",
        "id": "0675",
        "name": "reverse hyper extension (on stability ball)",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2297.gif",
        "id": "2297",
        "name": "stability ball crunch (full range hands behind head)",
        "target": "abs"
    }
]