export default [
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1274.gif",
        "id": "1274",
        "name": "deep push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0285.gif",
        "id": "0285",
        "name": "dumbbell alternate biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2403.gif",
        "id": "2403",
        "name": "dumbbell alternate biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1646.gif",
        "id": "1646",
        "name": "dumbbell alternate hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1647.gif",
        "id": "1647",
        "name": "dumbbell alternate preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1648.gif",
        "id": "1648",
        "name": "dumbbell alternate seated hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0286.gif",
        "id": "0286",
        "name": "dumbbell alternate side press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1649.gif",
        "id": "1649",
        "name": "dumbbell alternating bicep curl with leg raised on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1650.gif",
        "id": "1650",
        "name": "dumbbell alternating seated bicep curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2137.gif",
        "id": "2137",
        "name": "dumbbell arnold press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0287.gif",
        "id": "0287",
        "name": "dumbbell arnold press v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0288.gif",
        "id": "0288",
        "name": "dumbbell around pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0289.gif",
        "id": "0289",
        "name": "dumbbell bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0290.gif",
        "id": "0290",
        "name": "dumbbell bench seated press",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0291.gif",
        "id": "0291",
        "name": "dumbbell bench squat",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0293.gif",
        "id": "0293",
        "name": "dumbbell bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1651.gif",
        "id": "1651",
        "name": "dumbbell bicep curl lunge with bowling motion",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1652.gif",
        "id": "1652",
        "name": "dumbbell bicep curl on exercise ball with leg raised",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1653.gif",
        "id": "1653",
        "name": "dumbbell bicep curl with stork stance",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0294.gif",
        "id": "0294",
        "name": "dumbbell biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2401.gif",
        "id": "2401",
        "name": "dumbbell biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1654.gif",
        "id": "1654",
        "name": "dumbbell biceps curl reverse",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1655.gif",
        "id": "1655",
        "name": "dumbbell biceps curl squat",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1656.gif",
        "id": "1656",
        "name": "dumbbell biceps curl v sit on bosu ball",
        "target": "biceps"
    },
    {
        "bodyPart": "cardio",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1201.gif",
        "id": "1201",
        "name": "dumbbell burpee",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0295.gif",
        "id": "0295",
        "name": "dumbbell clean",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1731.gif",
        "id": "1731",
        "name": "dumbbell close grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0296.gif",
        "id": "0296",
        "name": "dumbbell close-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0297.gif",
        "id": "0297",
        "name": "dumbbell concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3635.gif",
        "id": "3635",
        "name": "dumbbell contralateral forward lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0298.gif",
        "id": "0298",
        "name": "dumbbell cross body hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1657.gif",
        "id": "1657",
        "name": "dumbbell cross body hammer curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0299.gif",
        "id": "0299",
        "name": "dumbbell cuban press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2136.gif",
        "id": "2136",
        "name": "dumbbell cuban press v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0300.gif",
        "id": "0300",
        "name": "dumbbell deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0301.gif",
        "id": "0301",
        "name": "dumbbell decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0302.gif",
        "id": "0302",
        "name": "dumbbell decline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0303.gif",
        "id": "0303",
        "name": "dumbbell decline hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1276.gif",
        "id": "1276",
        "name": "dumbbell decline one arm fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1617.gif",
        "id": "1617",
        "name": "dumbbell decline one arm hammer press",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0305.gif",
        "id": "0305",
        "name": "dumbbell decline shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0304.gif",
        "id": "0304",
        "name": "dumbbell decline shrug v. 2",
        "target": "traps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0306.gif",
        "id": "0306",
        "name": "dumbbell decline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0307.gif",
        "id": "0307",
        "name": "dumbbell decline twist fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1437.gif",
        "id": "1437",
        "name": "dumbbell finger curls",
        "target": "forearms"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0308.gif",
        "id": "0308",
        "name": "dumbbell fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1277.gif",
        "id": "1277",
        "name": "dumbbell fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1732.gif",
        "id": "1732",
        "name": "dumbbell forward lunge triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0310.gif",
        "id": "0310",
        "name": "dumbbell front raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0309.gif",
        "id": "0309",
        "name": "dumbbell front raise v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0311.gif",
        "id": "0311",
        "name": "dumbbell full can lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1760.gif",
        "id": "1760",
        "name": "dumbbell goblet squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0313.gif",
        "id": "0313",
        "name": "dumbbell hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1659.gif",
        "id": "1659",
        "name": "dumbbell hammer curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0312.gif",
        "id": "0312",
        "name": "dumbbell hammer curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2402.gif",
        "id": "2402",
        "name": "dumbbell hammer curls (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1664.gif",
        "id": "1664",
        "name": "dumbbell high curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3545.gif",
        "id": "3545",
        "name": "dumbbell incline alternate press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0314.gif",
        "id": "0314",
        "name": "dumbbell incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0315.gif",
        "id": "0315",
        "name": "dumbbell incline biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0316.gif",
        "id": "0316",
        "name": "dumbbell incline breeding",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0318.gif",
        "id": "0318",
        "name": "dumbbell incline curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0317.gif",
        "id": "0317",
        "name": "dumbbell incline curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0319.gif",
        "id": "0319",
        "name": "dumbbell incline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1278.gif",
        "id": "1278",
        "name": "dumbbell incline fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0320.gif",
        "id": "0320",
        "name": "dumbbell incline hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0321.gif",
        "id": "0321",
        "name": "dumbbell incline hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1618.gif",
        "id": "1618",
        "name": "dumbbell incline hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0322.gif",
        "id": "0322",
        "name": "dumbbell incline inner biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1279.gif",
        "id": "1279",
        "name": "dumbbell incline one arm fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1280.gif",
        "id": "1280",
        "name": "dumbbell incline one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1619.gif",
        "id": "1619",
        "name": "dumbbell incline one arm hammer press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1620.gif",
        "id": "1620",
        "name": "dumbbell incline one arm hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0323.gif",
        "id": "0323",
        "name": "dumbbell incline one arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1281.gif",
        "id": "1281",
        "name": "dumbbell incline one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1282.gif",
        "id": "1282",
        "name": "dumbbell incline one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0324.gif",
        "id": "0324",
        "name": "dumbbell incline palm-in press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1283.gif",
        "id": "1283",
        "name": "dumbbell incline press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0325.gif",
        "id": "0325",
        "name": "dumbbell incline raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0326.gif",
        "id": "0326",
        "name": "dumbbell incline rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0327.gif",
        "id": "0327",
        "name": "dumbbell incline row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0328.gif",
        "id": "0328",
        "name": "dumbbell incline shoulder raise",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0329.gif",
        "id": "0329",
        "name": "dumbbell incline shrug",
        "target": "traps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3542.gif",
        "id": "3542",
        "name": "dumbbell incline t-raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0330.gif",
        "id": "0330",
        "name": "dumbbell incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0331.gif",
        "id": "0331",
        "name": "dumbbell incline twisted flyes",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1733.gif",
        "id": "1733",
        "name": "dumbbell incline two arm extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3541.gif",
        "id": "3541",
        "name": "dumbbell incline y-raise",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0332.gif",
        "id": "0332",
        "name": "dumbbell iron cross",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0333.gif",
        "id": "0333",
        "name": "dumbbell kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1734.gif",
        "id": "1734",
        "name": "dumbbell kickbacks on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1660.gif",
        "id": "1660",
        "name": "dumbbell kneeling bicep curl exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0334.gif",
        "id": "0334",
        "name": "dumbbell lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0335.gif",
        "id": "0335",
        "name": "dumbbell lateral to front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0336.gif",
        "id": "0336",
        "name": "dumbbell lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1658.gif",
        "id": "1658",
        "name": "dumbbell lunge with bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0337.gif",
        "id": "0337",
        "name": "dumbbell lying  extension (across face)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1729.gif",
        "id": "1729",
        "name": "dumbbell lying alternate extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0338.gif",
        "id": "0338",
        "name": "dumbbell lying elbow press",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0863.gif",
        "id": "0863",
        "name": "dumbbell lying external shoulder rotation",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0339.gif",
        "id": "0339",
        "name": "dumbbell lying femoral",
        "target": "hamstrings"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0340.gif",
        "id": "0340",
        "name": "dumbbell lying hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2470.gif",
        "id": "2470",
        "name": "dumbbell lying on floor rear delt raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0341.gif",
        "id": "0341",
        "name": "dumbbell lying one arm deltoid rear",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0343.gif",
        "id": "0343",
        "name": "dumbbell lying one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0342.gif",
        "id": "0342",
        "name": "dumbbell lying one arm press v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0344.gif",
        "id": "0344",
        "name": "dumbbell lying one arm pronated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0345.gif",
        "id": "0345",
        "name": "dumbbell lying one arm rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0346.gif",
        "id": "0346",
        "name": "dumbbell lying one arm supinated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0347.gif",
        "id": "0347",
        "name": "dumbbell lying pronation",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2705.gif",
        "id": "2705",
        "name": "dumbbell lying pronation on floor",
        "target": "forearms"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1284.gif",
        "id": "1284",
        "name": "dumbbell lying pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1328.gif",
        "id": "1328",
        "name": "dumbbell lying rear delt row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0348.gif",
        "id": "0348",
        "name": "dumbbell lying rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1735.gif",
        "id": "1735",
        "name": "dumbbell lying single extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0349.gif",
        "id": "0349",
        "name": "dumbbell lying supination",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2706.gif",
        "id": "2706",
        "name": "dumbbell lying supination on floor",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1661.gif",
        "id": "1661",
        "name": "dumbbell lying supine biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0350.gif",
        "id": "0350",
        "name": "dumbbell lying supine curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0351.gif",
        "id": "0351",
        "name": "dumbbell lying triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1662.gif",
        "id": "1662",
        "name": "dumbbell lying wide curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0352.gif",
        "id": "0352",
        "name": "dumbbell neutral grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1285.gif",
        "id": "1285",
        "name": "dumbbell one arm bench fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0292.gif",
        "id": "0292",
        "name": "dumbbell one arm bent-over row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1286.gif",
        "id": "1286",
        "name": "dumbbell one arm chest fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0353.gif",
        "id": "0353",
        "name": "dumbbell one arm concetration curl (on stability ball)",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1287.gif",
        "id": "1287",
        "name": "dumbbell one arm decline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1288.gif",
        "id": "1288",
        "name": "dumbbell one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1736.gif",
        "id": "1736",
        "name": "dumbbell one arm french press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1663.gif",
        "id": "1663",
        "name": "dumbbell one arm hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1621.gif",
        "id": "1621",
        "name": "dumbbell one arm hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1289.gif",
        "id": "1289",
        "name": "dumbbell one arm incline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0354.gif",
        "id": "0354",
        "name": "dumbbell one arm kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0355.gif",
        "id": "0355",
        "name": "dumbbell one arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0356.gif",
        "id": "0356",
        "name": "dumbbell one arm lateral raise with support",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1290.gif",
        "id": "1290",
        "name": "dumbbell one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1665.gif",
        "id": "1665",
        "name": "dumbbell one arm prone curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1666.gif",
        "id": "1666",
        "name": "dumbbell one arm prone hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1291.gif",
        "id": "1291",
        "name": "dumbbell one arm pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0358.gif",
        "id": "0358",
        "name": "dumbbell one arm revers wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0359.gif",
        "id": "0359",
        "name": "dumbbell one arm reverse fly (with support)",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1622.gif",
        "id": "1622",
        "name": "dumbbell one arm reverse grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1414.gif",
        "id": "1414",
        "name": "dumbbell one arm reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1667.gif",
        "id": "1667",
        "name": "dumbbell one arm reverse spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1668.gif",
        "id": "1668",
        "name": "dumbbell one arm seated bicep curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1669.gif",
        "id": "1669",
        "name": "dumbbell one arm seated hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1415.gif",
        "id": "1415",
        "name": "dumbbell one arm seated neutral wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0361.gif",
        "id": "0361",
        "name": "dumbbell one arm shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0360.gif",
        "id": "0360",
        "name": "dumbbell one arm shoulder press v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3888.gif",
        "id": "3888",
        "name": "dumbbell one arm snatch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1670.gif",
        "id": "1670",
        "name": "dumbbell one arm standing curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1671.gif",
        "id": "1671",
        "name": "dumbbell one arm standing hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0362.gif",
        "id": "0362",
        "name": "dumbbell one arm triceps extension (on bench)",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0363.gif",
        "id": "0363",
        "name": "dumbbell one arm upright row",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0364.gif",
        "id": "0364",
        "name": "dumbbell one arm wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1672.gif",
        "id": "1672",
        "name": "dumbbell one arm zottman preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1292.gif",
        "id": "1292",
        "name": "dumbbell one leg fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0365.gif",
        "id": "0365",
        "name": "dumbbell over bench neutral wrist curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0366.gif",
        "id": "0366",
        "name": "dumbbell over bench one arm  neutral wrist curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1441.gif",
        "id": "1441",
        "name": "dumbbell over bench one arm reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0367.gif",
        "id": "0367",
        "name": "dumbbell over bench one arm wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0368.gif",
        "id": "0368",
        "name": "dumbbell over bench revers wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0369.gif",
        "id": "0369",
        "name": "dumbbell over bench wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1329.gif",
        "id": "1329",
        "name": "dumbbell palm rotational bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1623.gif",
        "id": "1623",
        "name": "dumbbell palms in incline bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0370.gif",
        "id": "0370",
        "name": "dumbbell peacher hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0371.gif",
        "id": "0371",
        "name": "dumbbell plyo squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0372.gif",
        "id": "0372",
        "name": "dumbbell preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1673.gif",
        "id": "1673",
        "name": "dumbbell preacher curl over exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1293.gif",
        "id": "1293",
        "name": "dumbbell press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0373.gif",
        "id": "0373",
        "name": "dumbbell pronate-grip triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0374.gif",
        "id": "0374",
        "name": "dumbbell prone incline curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1674.gif",
        "id": "1674",
        "name": "dumbbell prone incline hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0375.gif",
        "id": "0375",
        "name": "dumbbell pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1294.gif",
        "id": "1294",
        "name": "dumbbell pullover hip extension on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1295.gif",
        "id": "1295",
        "name": "dumbbell pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1700.gif",
        "id": "1700",
        "name": "dumbbell push press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0376.gif",
        "id": "0376",
        "name": "dumbbell raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2292.gif",
        "id": "2292",
        "name": "dumbbell rear delt raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0377.gif",
        "id": "0377",
        "name": "dumbbell rear delt row_shoulder",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0378.gif",
        "id": "0378",
        "name": "dumbbell rear fly",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0380.gif",
        "id": "0380",
        "name": "dumbbell rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0379.gif",
        "id": "0379",
        "name": "dumbbell rear lateral raise (support head)",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0381.gif",
        "id": "0381",
        "name": "dumbbell rear lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0382.gif",
        "id": "0382",
        "name": "dumbbell revers grip biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1624.gif",
        "id": "1624",
        "name": "dumbbell reverse bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0383.gif",
        "id": "0383",
        "name": "dumbbell reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1330.gif",
        "id": "1330",
        "name": "dumbbell reverse grip incline bench one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1331.gif",
        "id": "1331",
        "name": "dumbbell reverse grip incline bench two arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2327.gif",
        "id": "2327",
        "name": "dumbbell reverse grip row (female)",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0384.gif",
        "id": "0384",
        "name": "dumbbell reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1675.gif",
        "id": "1675",
        "name": "dumbbell reverse spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0385.gif",
        "id": "0385",
        "name": "dumbbell reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1459.gif",
        "id": "1459",
        "name": "dumbbell romanian deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0386.gif",
        "id": "0386",
        "name": "dumbbell rotation reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2397.gif",
        "id": "2397",
        "name": "dumbbell scott press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0387.gif",
        "id": "0387",
        "name": "dumbbell seated alternate front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1676.gif",
        "id": "1676",
        "name": "dumbbell seated alternate hammer curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0388.gif",
        "id": "0388",
        "name": "dumbbell seated alternate press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3546.gif",
        "id": "3546",
        "name": "dumbbell seated alternate shoulder",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0389.gif",
        "id": "0389",
        "name": "dumbbell seated bench extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2317.gif",
        "id": "2317",
        "name": "dumbbell seated bent arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1730.gif",
        "id": "1730",
        "name": "dumbbell seated bent over alternate kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1737.gif",
        "id": "1737",
        "name": "dumbbell seated bent over triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1677.gif",
        "id": "1677",
        "name": "dumbbell seated bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0390.gif",
        "id": "0390",
        "name": "dumbbell seated biceps curl (on stability ball)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3547.gif",
        "id": "3547",
        "name": "dumbbell seated biceps curl to shoulder press",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1379.gif",
        "id": "1379",
        "name": "dumbbell seated calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0391.gif",
        "id": "0391",
        "name": "dumbbell seated curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0392.gif",
        "id": "0392",
        "name": "dumbbell seated front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1678.gif",
        "id": "1678",
        "name": "dumbbell seated hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0393.gif",
        "id": "0393",
        "name": "dumbbell seated inner biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0394.gif",
        "id": "0394",
        "name": "dumbbell seated kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0396.gif",
        "id": "0396",
        "name": "dumbbell seated lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0395.gif",
        "id": "0395",
        "name": "dumbbell seated lateral raise v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0397.gif",
        "id": "0397",
        "name": "dumbbell seated neutral wrist curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1679.gif",
        "id": "1679",
        "name": "dumbbell seated one arm bicep curl on exercise ball with leg raised",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0398.gif",
        "id": "0398",
        "name": "dumbbell seated one arm kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0399.gif",
        "id": "0399",
        "name": "dumbbell seated one arm rotate",
        "target": "forearms"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0400.gif",
        "id": "0400",
        "name": "dumbbell seated one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1380.gif",
        "id": "1380",
        "name": "dumbbell seated one leg calf raise - hammer grip",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1381.gif",
        "id": "1381",
        "name": "dumbbell seated one leg calf raise - palm up",
        "target": "calves"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0401.gif",
        "id": "0401",
        "name": "dumbbell seated palms up wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0402.gif",
        "id": "0402",
        "name": "dumbbell seated preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0403.gif",
        "id": "0403",
        "name": "dumbbell seated revers grip concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1738.gif",
        "id": "1738",
        "name": "dumbbell seated reverse grip one arm overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0405.gif",
        "id": "0405",
        "name": "dumbbell seated shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0404.gif",
        "id": "0404",
        "name": "dumbbell seated shoulder press (parallel grip)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2188.gif",
        "id": "2188",
        "name": "dumbbell seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0406.gif",
        "id": "0406",
        "name": "dumbbell shrug",
        "target": "traps"
    },
    {
        "bodyPart": "waist",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0407.gif",
        "id": "0407",
        "name": "dumbbell side bend",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0408.gif",
        "id": "0408",
        "name": "dumbbell side lying one hand raise",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3664.gif",
        "id": "3664",
        "name": "dumbbell side plank with rear fly",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3548.gif",
        "id": "3548",
        "name": "dumbbell single arm overhead carry",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0409.gif",
        "id": "0409",
        "name": "dumbbell single leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1757.gif",
        "id": "1757",
        "name": "dumbbell single leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2805.gif",
        "id": "2805",
        "name": "dumbbell single leg deadlift with stepbox support",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0410.gif",
        "id": "0410",
        "name": "dumbbell single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0411.gif",
        "id": "0411",
        "name": "dumbbell single leg squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0413.gif",
        "id": "0413",
        "name": "dumbbell squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3560.gif",
        "id": "3560",
        "name": "dumbbell standing alternate hammer curl and press",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0414.gif",
        "id": "0414",
        "name": "dumbbell standing alternate overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0415.gif",
        "id": "0415",
        "name": "dumbbell standing alternate raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1739.gif",
        "id": "1739",
        "name": "dumbbell standing alternating tricep kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2143.gif",
        "id": "2143",
        "name": "dumbbell standing around world",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1740.gif",
        "id": "1740",
        "name": "dumbbell standing bent over one arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1741.gif",
        "id": "1741",
        "name": "dumbbell standing bent over two arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0416.gif",
        "id": "0416",
        "name": "dumbbell standing biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0417.gif",
        "id": "0417",
        "name": "dumbbell standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0418.gif",
        "id": "0418",
        "name": "dumbbell standing concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0419.gif",
        "id": "0419",
        "name": "dumbbell standing front raise above head",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2321.gif",
        "id": "2321",
        "name": "dumbbell standing inner biceps curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0420.gif",
        "id": "0420",
        "name": "dumbbell standing kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0421.gif",
        "id": "0421",
        "name": "dumbbell standing one arm concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0422.gif",
        "id": "0422",
        "name": "dumbbell standing one arm curl (over incline bench)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1680.gif",
        "id": "1680",
        "name": "dumbbell standing one arm curl over incline bench",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0423.gif",
        "id": "0423",
        "name": "dumbbell standing one arm extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0424.gif",
        "id": "0424",
        "name": "dumbbell standing one arm palm in press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0425.gif",
        "id": "0425",
        "name": "dumbbell standing one arm reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0426.gif",
        "id": "0426",
        "name": "dumbbell standing overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0427.gif",
        "id": "0427",
        "name": "dumbbell standing palms in press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0428.gif",
        "id": "0428",
        "name": "dumbbell standing preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0429.gif",
        "id": "0429",
        "name": "dumbbell standing reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0430.gif",
        "id": "0430",
        "name": "dumbbell standing triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2293.gif",
        "id": "2293",
        "name": "dumbbell standing zottman preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1684.gif",
        "id": "1684",
        "name": "dumbbell step up single leg balance with bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0431.gif",
        "id": "0431",
        "name": "dumbbell step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2796.gif",
        "id": "2796",
        "name": "dumbbell step-up lunge",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2812.gif",
        "id": "2812",
        "name": "dumbbell step-up split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0432.gif",
        "id": "0432",
        "name": "dumbbell stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0433.gif",
        "id": "0433",
        "name": "dumbbell straight arm pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0434.gif",
        "id": "0434",
        "name": "dumbbell straight leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2808.gif",
        "id": "2808",
        "name": "dumbbell sumo pull through",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2803.gif",
        "id": "2803",
        "name": "dumbbell supported squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0436.gif",
        "id": "0436",
        "name": "dumbbell tate press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1742.gif",
        "id": "1742",
        "name": "dumbbell tricep kickback with stork stance",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1743.gif",
        "id": "1743",
        "name": "dumbbell twisting bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0437.gif",
        "id": "0437",
        "name": "dumbbell upright row",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1765.gif",
        "id": "1765",
        "name": "dumbbell upright row (back pov)",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0864.gif",
        "id": "0864",
        "name": "dumbbell upright shoulder external rotation",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/5201.gif",
        "id": "5201",
        "name": "dumbbell waiter biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0438.gif",
        "id": "0438",
        "name": "dumbbell w-press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0439.gif",
        "id": "0439",
        "name": "dumbbell zottman curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2294.gif",
        "id": "2294",
        "name": "dumbbell zottman preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2189.gif",
        "id": "2189",
        "name": "dumbbells seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1382.gif",
        "id": "1382",
        "name": "exercise ball on the wall calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3241.gif",
        "id": "3241",
        "name": "exercise ball on the wall calf raise (tennis ball between ankles)",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3240.gif",
        "id": "3240",
        "name": "exercise ball on the wall calf raise (tennis ball between knees)",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1746.gif",
        "id": "1746",
        "name": "exercise ball supine triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2133.gif",
        "id": "2133",
        "name": "farmers walk",
        "target": "quads"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3234.gif",
        "id": "3234",
        "name": "hyght dumbbell fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0660.gif",
        "id": "0660",
        "name": "push-up close-grip off dumbbell",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0727.gif",
        "id": "0727",
        "name": "single leg calf raise (on a dumbbell)",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0777.gif",
        "id": "0777",
        "name": "spell caster",
        "target": "abs"
    }
]