export default [
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1512.gif",
        "id": "1512",
        "name": "all fours squad stretch",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1713.gif",
        "id": "1713",
        "name": "assisted prone lying quads stretch",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1473.gif",
        "id": "1473",
        "name": "backward jump",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0020.gif",
        "id": "0020",
        "name": "balance board",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0987.gif",
        "id": "0987",
        "name": "band one arm single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1001.gif",
        "id": "1001",
        "name": "band single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0024.gif",
        "id": "0024",
        "name": "barbell bench front squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0026.gif",
        "id": "0026",
        "name": "barbell bench squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0028.gif",
        "id": "0028",
        "name": "barbell clean and press",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0068.gif",
        "id": "0068",
        "name": "barbell one leg squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0069.gif",
        "id": "0069",
        "name": "barbell overhead squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0098.gif",
        "id": "0098",
        "name": "barbell side split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0097.gif",
        "id": "0097",
        "name": "barbell side split squat v. 2",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0099.gif",
        "id": "0099",
        "name": "barbell single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2810.gif",
        "id": "2810",
        "name": "barbell split squat v. 2",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0102.gif",
        "id": "0102",
        "name": "barbell squat (on knees)",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2798.gif",
        "id": "2798",
        "name": "barbell squat jump step rear lunge",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0124.gif",
        "id": "0124",
        "name": "barbell wide squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1548.gif",
        "id": "1548",
        "name": "chair leg extended stretch",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1760.gif",
        "id": "1760",
        "name": "dumbbell goblet squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0410.gif",
        "id": "0410",
        "name": "dumbbell single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2796.gif",
        "id": "2796",
        "name": "dumbbell step-up lunge",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2812.gif",
        "id": "2812",
        "name": "dumbbell step-up split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2803.gif",
        "id": "2803",
        "name": "dumbbell supported squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2133.gif",
        "id": "2133",
        "name": "farmers walk",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1472.gif",
        "id": "1472",
        "name": "forward jump",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "rope",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1564.gif",
        "id": "1564",
        "name": "intermediate hip flexor and quad stretch",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2287.gif",
        "id": "2287",
        "name": "lever alternate leg press ",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0585.gif",
        "id": "0585",
        "name": "lever leg extension",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0613.gif",
        "id": "0613",
        "name": "lying (side) quads stretch",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3533.gif",
        "id": "3533",
        "name": "quads",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3552.gif",
        "id": "3552",
        "name": "quick feet v. 2",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3007.gif",
        "id": "3007",
        "name": "resistance band leg extension",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1489.gif",
        "id": "1489",
        "name": "sissy squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0750.gif",
        "id": "0750",
        "name": "smith chair squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0768.gif",
        "id": "0768",
        "name": "smith single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0776.gif",
        "id": "0776",
        "name": "snatch pull",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2368.gif",
        "id": "2368",
        "name": "split squats",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0786.gif",
        "id": "0786",
        "name": "squat jerk",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "bosu ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1705.gif",
        "id": "1705",
        "name": "squat on bosu ball",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1685.gif",
        "id": "1685",
        "name": "squat to overhead reach",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1686.gif",
        "id": "1686",
        "name": "squat to overhead reach with twist",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0809.gif",
        "id": "0809",
        "name": "suspended split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0851.gif",
        "id": "0851",
        "name": "weighted sissy squat",
        "target": "quads"
    }
]