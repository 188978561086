export default [
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0007.gif",
        "id": "0007",
        "name": "alternate lateral pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0148.gif",
        "id": "0148",
        "name": "cable alternate shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0149.gif",
        "id": "0149",
        "name": "cable alternate triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3235.gif",
        "id": "3235",
        "name": "cable assisted inverse leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0150.gif",
        "id": "0150",
        "name": "cable bar lateral pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0151.gif",
        "id": "0151",
        "name": "cable bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1630.gif",
        "id": "1630",
        "name": "cable close grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1631.gif",
        "id": "1631",
        "name": "cable concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0152.gif",
        "id": "0152",
        "name": "cable concentration extension (on knee)",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0153.gif",
        "id": "0153",
        "name": "cable cross-over lateral pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0154.gif",
        "id": "0154",
        "name": "cable cross-over revers fly",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0155.gif",
        "id": "0155",
        "name": "cable cross-over variation",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0868.gif",
        "id": "0868",
        "name": "cable curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0157.gif",
        "id": "0157",
        "name": "cable deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0158.gif",
        "id": "0158",
        "name": "cable decline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1260.gif",
        "id": "1260",
        "name": "cable decline one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1261.gif",
        "id": "1261",
        "name": "cable decline press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0159.gif",
        "id": "0159",
        "name": "cable decline seated wide-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1632.gif",
        "id": "1632",
        "name": "cable drag curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0160.gif",
        "id": "0160",
        "name": "cable floor seated wide-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0161.gif",
        "id": "0161",
        "name": "cable forward raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0162.gif",
        "id": "0162",
        "name": "cable front raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0164.gif",
        "id": "0164",
        "name": "cable front shoulder raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0165.gif",
        "id": "0165",
        "name": "cable hammer curl (with rope)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1722.gif",
        "id": "1722",
        "name": "cable high pulley overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0167.gif",
        "id": "0167",
        "name": "cable high row (kneeling)",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0168.gif",
        "id": "0168",
        "name": "cable hip adduction",
        "target": "adductors"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0169.gif",
        "id": "0169",
        "name": "cable incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1318.gif",
        "id": "1318",
        "name": "cable incline bench row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0171.gif",
        "id": "0171",
        "name": "cable incline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0170.gif",
        "id": "0170",
        "name": "cable incline fly (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0172.gif",
        "id": "0172",
        "name": "cable incline pushdown",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0173.gif",
        "id": "0173",
        "name": "cable incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0174.gif",
        "id": "0174",
        "name": "cable judo flip",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0860.gif",
        "id": "0860",
        "name": "cable kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0175.gif",
        "id": "0175",
        "name": "cable kneeling crunch",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3697.gif",
        "id": "3697",
        "name": "cable kneeling rear delt row (with rope) (male)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0176.gif",
        "id": "0176",
        "name": "cable kneeling triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2330.gif",
        "id": "2330",
        "name": "cable lat pulldown full range of motion",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0177.gif",
        "id": "0177",
        "name": "cable lateral pulldown (with rope attachment)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2616.gif",
        "id": "2616",
        "name": "cable lateral pulldown with v-bar",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0178.gif",
        "id": "0178",
        "name": "cable lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0179.gif",
        "id": "0179",
        "name": "cable low fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0180.gif",
        "id": "0180",
        "name": "cable low seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1634.gif",
        "id": "1634",
        "name": "cable lying bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0182.gif",
        "id": "0182",
        "name": "cable lying close-grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0184.gif",
        "id": "0184",
        "name": "cable lying extension pullover (with rope attachment)",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0185.gif",
        "id": "0185",
        "name": "cable lying fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0186.gif",
        "id": "0186",
        "name": "cable lying triceps extension v. 2",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0188.gif",
        "id": "0188",
        "name": "cable middle fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0189.gif",
        "id": "0189",
        "name": "cable one arm bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0190.gif",
        "id": "0190",
        "name": "cable one arm curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1262.gif",
        "id": "1262",
        "name": "cable one arm decline chest fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1263.gif",
        "id": "1263",
        "name": "cable one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1264.gif",
        "id": "1264",
        "name": "cable one arm incline fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1265.gif",
        "id": "1265",
        "name": "cable one arm incline press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1266.gif",
        "id": "1266",
        "name": "cable one arm incline press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0191.gif",
        "id": "0191",
        "name": "cable one arm lateral bent-over",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0192.gif",
        "id": "0192",
        "name": "cable one arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1633.gif",
        "id": "1633",
        "name": "cable one arm preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1267.gif",
        "id": "1267",
        "name": "cable one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3563.gif",
        "id": "3563",
        "name": "cable one arm pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1635.gif",
        "id": "1635",
        "name": "cable one arm reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0193.gif",
        "id": "0193",
        "name": "cable one arm straight back high row (kneeling)",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1723.gif",
        "id": "1723",
        "name": "cable one arm tricep pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1636.gif",
        "id": "1636",
        "name": "cable overhead curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1637.gif",
        "id": "1637",
        "name": "cable overhead curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0194.gif",
        "id": "0194",
        "name": "cable overhead triceps extension (rope attachment)",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1319.gif",
        "id": "1319",
        "name": "cable palm rotational row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0195.gif",
        "id": "0195",
        "name": "cable preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1268.gif",
        "id": "1268",
        "name": "cable press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0196.gif",
        "id": "0196",
        "name": "cable pull through (with rope)",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0198.gif",
        "id": "0198",
        "name": "cable pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0197.gif",
        "id": "0197",
        "name": "cable pulldown (pro lat bar)",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1638.gif",
        "id": "1638",
        "name": "cable pulldown bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0201.gif",
        "id": "0201",
        "name": "cable pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0199.gif",
        "id": "0199",
        "name": "cable pushdown (straight arm) v. 2",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0200.gif",
        "id": "0200",
        "name": "cable pushdown (with rope attachment)",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0202.gif",
        "id": "0202",
        "name": "cable rear delt row (stirrups)",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0203.gif",
        "id": "0203",
        "name": "cable rear delt row (with rope)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0204.gif",
        "id": "0204",
        "name": "cable rear drive",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0205.gif",
        "id": "0205",
        "name": "cable rear pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0873.gif",
        "id": "0873",
        "name": "cable reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0206.gif",
        "id": "0206",
        "name": "cable reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2406.gif",
        "id": "2406",
        "name": "cable reverse grip triceps pushdown (sz-bar) (with arm blaster)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1413.gif",
        "id": "1413",
        "name": "cable reverse one arm curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0209.gif",
        "id": "0209",
        "name": "cable reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0210.gif",
        "id": "0210",
        "name": "cable reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0207.gif",
        "id": "0207",
        "name": "cable reverse-grip pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0208.gif",
        "id": "0208",
        "name": "cable reverse-grip straight back seated high row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1320.gif",
        "id": "1320",
        "name": "cable rope crossover seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1321.gif",
        "id": "1321",
        "name": "cable rope elevated seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1322.gif",
        "id": "1322",
        "name": "cable rope extension incline bench row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1639.gif",
        "id": "1639",
        "name": "cable rope hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1724.gif",
        "id": "1724",
        "name": "cable rope high pulley overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1725.gif",
        "id": "1725",
        "name": "cable rope incline tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1726.gif",
        "id": "1726",
        "name": "cable rope lying on floor tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1640.gif",
        "id": "1640",
        "name": "cable rope one arm hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1323.gif",
        "id": "1323",
        "name": "cable rope seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0211.gif",
        "id": "0211",
        "name": "cable russian twists (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2144.gif",
        "id": "2144",
        "name": "cable seated chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0212.gif",
        "id": "0212",
        "name": "cable seated crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1641.gif",
        "id": "1641",
        "name": "cable seated curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0213.gif",
        "id": "0213",
        "name": "cable seated high row (v-bar)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0214.gif",
        "id": "0214",
        "name": "cable seated one arm alternate row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1642.gif",
        "id": "1642",
        "name": "cable seated one arm concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1643.gif",
        "id": "1643",
        "name": "cable seated overhead curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0215.gif",
        "id": "0215",
        "name": "cable seated rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0861.gif",
        "id": "0861",
        "name": "cable seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0216.gif",
        "id": "0216",
        "name": "cable seated shoulder internal rotation",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2399.gif",
        "id": "2399",
        "name": "cable seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0218.gif",
        "id": "0218",
        "name": "cable seated wide-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0219.gif",
        "id": "0219",
        "name": "cable shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0220.gif",
        "id": "0220",
        "name": "cable shrug",
        "target": "traps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0222.gif",
        "id": "0222",
        "name": "cable side bend",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0221.gif",
        "id": "0221",
        "name": "cable side bend crunch (bosu ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0223.gif",
        "id": "0223",
        "name": "cable side crunch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1717.gif",
        "id": "1717",
        "name": "cable squat row (with rope attachment)",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1644.gif",
        "id": "1644",
        "name": "cable squatting curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0224.gif",
        "id": "0224",
        "name": "cable standing back wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1375.gif",
        "id": "1375",
        "name": "cable standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0225.gif",
        "id": "0225",
        "name": "cable standing cross-over high reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0226.gif",
        "id": "0226",
        "name": "cable standing crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0874.gif",
        "id": "0874",
        "name": "cable standing crunch (with rope attachment)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0227.gif",
        "id": "0227",
        "name": "cable standing fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0228.gif",
        "id": "0228",
        "name": "cable standing hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0229.gif",
        "id": "0229",
        "name": "cable standing inner curl",
        "target": "biceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0230.gif",
        "id": "0230",
        "name": "cable standing lift",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0231.gif",
        "id": "0231",
        "name": "cable standing one arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1376.gif",
        "id": "1376",
        "name": "cable standing one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0232.gif",
        "id": "0232",
        "name": "cable standing pulldown (with rope)",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0233.gif",
        "id": "0233",
        "name": "cable standing rear delt row (with rope)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1727.gif",
        "id": "1727",
        "name": "cable standing reverse grip one arm overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0234.gif",
        "id": "0234",
        "name": "cable standing row (v-bar)",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0235.gif",
        "id": "0235",
        "name": "cable standing shoulder external rotation",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0236.gif",
        "id": "0236",
        "name": "cable standing twist row (v-bar)",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1269.gif",
        "id": "1269",
        "name": "cable standing up straight crossovers",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0238.gif",
        "id": "0238",
        "name": "cable straight arm pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0237.gif",
        "id": "0237",
        "name": "cable straight arm pulldown (with rope)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0239.gif",
        "id": "0239",
        "name": "cable straight back seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0240.gif",
        "id": "0240",
        "name": "cable supine reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2464.gif",
        "id": "2464",
        "name": "cable thibaudeau kayak row",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0241.gif",
        "id": "0241",
        "name": "cable triceps pushdown (v-bar)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/2405.gif",
        "id": "2405",
        "name": "cable triceps pushdown (v-bar) (with arm blaster)",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0242.gif",
        "id": "0242",
        "name": "cable tuck reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0243.gif",
        "id": "0243",
        "name": "cable twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0862.gif",
        "id": "0862",
        "name": "cable twist (up-down)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0244.gif",
        "id": "0244",
        "name": "cable twisting pull",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1645.gif",
        "id": "1645",
        "name": "cable two arm curl on incline bench",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1728.gif",
        "id": "1728",
        "name": "cable two arm tricep kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0245.gif",
        "id": "0245",
        "name": "cable underhand pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1270.gif",
        "id": "1270",
        "name": "cable upper chest crossovers",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1324.gif",
        "id": "1324",
        "name": "cable upper row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0246.gif",
        "id": "0246",
        "name": "cable upright row",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1325.gif",
        "id": "1325",
        "name": "cable wide grip rear pulldown behind neck",
        "target": "lats"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0247.gif",
        "id": "0247",
        "name": "cable wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0818.gif",
        "id": "0818",
        "name": "twin handle parallel grip lat pulldown",
        "target": "lats"
    }
]