export default [
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1018.gif",
        "id": "1018",
        "name": "band shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0095.gif",
        "id": "0095",
        "name": "barbell shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0220.gif",
        "id": "0220",
        "name": "cable shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0305.gif",
        "id": "0305",
        "name": "dumbbell decline shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0304.gif",
        "id": "0304",
        "name": "dumbbell decline shrug v. 2",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0329.gif",
        "id": "0329",
        "name": "dumbbell incline shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0406.gif",
        "id": "0406",
        "name": "dumbbell shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0548.gif",
        "id": "0548",
        "name": "kettlebell sumo high pull",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0580.gif",
        "id": "0580",
        "name": "lever gripless shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1439.gif",
        "id": "1439",
        "name": "lever gripless shrug v. 2",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0604.gif",
        "id": "0604",
        "name": "lever shrug ",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3012.gif",
        "id": "3012",
        "name": "scapula dips",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0688.gif",
        "id": "0688",
        "name": "scapular pull-up",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0746.gif",
        "id": "0746",
        "name": "smith back shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0767.gif",
        "id": "0767",
        "name": "smith shrug",
        "target": "traps"
    }
]