export default [
    {
        "bodyPart": "waist",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0014.gif",
        "id": "0014",
        "name": "assisted motion russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1353.gif",
        "id": "1353",
        "name": "medicine ball catch and overhead throw",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1302.gif",
        "id": "1302",
        "name": "medicine ball chest pass",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1303.gif",
        "id": "1303",
        "name": "medicine ball chest push from 3 point stance",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1304.gif",
        "id": "1304",
        "name": "medicine ball chest push multiple response",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1305.gif",
        "id": "1305",
        "name": "medicine ball chest push single response",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1312.gif",
        "id": "1312",
        "name": "medicine ball chest push with run release",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1701.gif",
        "id": "1701",
        "name": "medicine ball close grip push up",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1354.gif",
        "id": "1354",
        "name": "medicine ball overhead slam",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1750.gif",
        "id": "1750",
        "name": "medicine ball supine chest throw",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0640.gif",
        "id": "0640",
        "name": "one arm slam (with medicine ball)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0663.gif",
        "id": "0663",
        "name": "push-up medicine ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0847.gif",
        "id": "0847",
        "name": "weighted seated bicep curl  (on stability ball)",
        "target": "biceps"
    }
]