export default [
    {
        "bodyPart": "waist",
        "equipment": "wheel roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0796.gif",
        "id": "0796",
        "name": "standing wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "wheel roller",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0857.gif",
        "id": "0857",
        "name": "wheel rollerout",
        "target": "abs"
    }
]