export default [
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0050.gif",
        "id": "0050",
        "name": "barbell incline shoulder raise",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0328.gif",
        "id": "0328",
        "name": "dumbbell incline shoulder raise",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3011.gif",
        "id": "3011",
        "name": "incline scapula push up",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3021.gif",
        "id": "3021",
        "name": "scapula push-up",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0759.gif",
        "id": "0759",
        "name": "smith incline shoulder raises",
        "target": "serratus anterior"
    }
]