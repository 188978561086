export default [
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0968.gif",
        "id": "0968",
        "name": "band alternating biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0969.gif",
        "id": "0969",
        "name": "band alternating v-up",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0970.gif",
        "id": "0970",
        "name": "band assisted pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0971.gif",
        "id": "0971",
        "name": "band assisted wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1254.gif",
        "id": "1254",
        "name": "band bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0980.gif",
        "id": "0980",
        "name": "band bent-over hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0972.gif",
        "id": "0972",
        "name": "band bicycle crunch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0974.gif",
        "id": "0974",
        "name": "band close-grip pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0975.gif",
        "id": "0975",
        "name": "band close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0976.gif",
        "id": "0976",
        "name": "band concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3117.gif",
        "id": "3117",
        "name": "band fixed back close grip pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/3116.gif",
        "id": "3116",
        "name": "band fixed back underhand pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0977.gif",
        "id": "0977",
        "name": "band front lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0978.gif",
        "id": "0978",
        "name": "band front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1408.gif",
        "id": "1408",
        "name": "band hip lift",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0979.gif",
        "id": "0979",
        "name": "band horizontal pallof press",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0981.gif",
        "id": "0981",
        "name": "band jack knife sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0983.gif",
        "id": "0983",
        "name": "band kneeling one arm pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0985.gif",
        "id": "0985",
        "name": "band kneeling twisting crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0984.gif",
        "id": "0984",
        "name": "band lying hip internal rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1002.gif",
        "id": "1002",
        "name": "band lying straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0986.gif",
        "id": "0986",
        "name": "band one arm overhead biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0987.gif",
        "id": "0987",
        "name": "band one arm single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0988.gif",
        "id": "0988",
        "name": "band one arm standing low row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0989.gif",
        "id": "0989",
        "name": "band one arm twisting chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0990.gif",
        "id": "0990",
        "name": "band one arm twisting seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0991.gif",
        "id": "0991",
        "name": "band pull through",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0992.gif",
        "id": "0992",
        "name": "band push sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0993.gif",
        "id": "0993",
        "name": "band reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0994.gif",
        "id": "0994",
        "name": "band reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0996.gif",
        "id": "0996",
        "name": "band seated hip internal rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1011.gif",
        "id": "1011",
        "name": "band seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0997.gif",
        "id": "0997",
        "name": "band shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1018.gif",
        "id": "1018",
        "name": "band shrug",
        "target": "traps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0998.gif",
        "id": "0998",
        "name": "band side triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/0999.gif",
        "id": "0999",
        "name": "band single leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1000.gif",
        "id": "1000",
        "name": "band single leg reverse calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1001.gif",
        "id": "1001",
        "name": "band single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1004.gif",
        "id": "1004",
        "name": "band squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1003.gif",
        "id": "1003",
        "name": "band squat row",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1005.gif",
        "id": "1005",
        "name": "band standing crunch",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1022.gif",
        "id": "1022",
        "name": "band standing rear delt row",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1007.gif",
        "id": "1007",
        "name": "band standing twisting crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1008.gif",
        "id": "1008",
        "name": "band step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1009.gif",
        "id": "1009",
        "name": "band stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1023.gif",
        "id": "1023",
        "name": "band straight back stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1010.gif",
        "id": "1010",
        "name": "band straight leg deadlift",
        "target": "spine"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1012.gif",
        "id": "1012",
        "name": "band twisting overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1369.gif",
        "id": "1369",
        "name": "band two legs calf raise - (band under both legs) v. 2",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1013.gif",
        "id": "1013",
        "name": "band underhand pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1014.gif",
        "id": "1014",
        "name": "band v-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1015.gif",
        "id": "1015",
        "name": "band vertical pallof press",
        "target": "abs"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1016.gif",
        "id": "1016",
        "name": "band wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "http://d205bpvrqc9yn1.cloudfront.net/1017.gif",
        "id": "1017",
        "name": "band y-raise",
        "target": "delts"
    }
]